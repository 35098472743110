/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulateEmailResp
 */
export interface SimulateEmailResp {
    /**
     * The subject of the outbound email
     * @type {string}
     * @memberof SimulateEmailResp
     */
    subject: string;
    /**
     * The content of the outbound email
     * @type {string}
     * @memberof SimulateEmailResp
     */
    content: string;
}

/**
 * Check if a given object implements the SimulateEmailResp interface.
 */
export function instanceOfSimulateEmailResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function SimulateEmailRespFromJSON(json: any): SimulateEmailResp {
    return SimulateEmailRespFromJSONTyped(json, false);
}

export function SimulateEmailRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateEmailResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'content': json['content'],
    };
}

export function SimulateEmailRespToJSON(value?: SimulateEmailResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'content': value.content,
    };
}

