import React from "react";
import { Modal, Button } from "@mui/material";

const SendFailModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          backgroundColor: "white",
          border: "2px solid #000",
          boxShadow: 24,
          padding: 16,
          textAlign: "center",
        }}
      >
        <h2 id="error-modal-title" data-testid="error-modal-title">
          Error
        </h2>
        <p id="error-modal-description">
          There was an error in sending/scheduling your messages.
          <br />
          <br />
          <br />
          <b>Please refresh the page and try again later.</b>
        </p>
        <br />
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default SendFailModal;
