/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductResp } from './ProductResp';
import {
    ProductRespFromJSON,
    ProductRespFromJSONTyped,
    ProductRespToJSON,
} from './ProductResp';

/**
 * 
 * @export
 * @interface ListProductResp
 */
export interface ListProductResp {
    /**
     * The total count of client product records
     * @type {number}
     * @memberof ListProductResp
     */
    count: number;
    /**
     * The index of the first record in the current result set
     * @type {number}
     * @memberof ListProductResp
     */
    startIndex: number;
    /**
     * The index of the last record in the current result set
     * @type {number}
     * @memberof ListProductResp
     */
    endIndex: number;
    /**
     * Indicates if more records are available
     * @type {boolean}
     * @memberof ListProductResp
     */
    isMore: boolean;
    /**
     * 
     * @type {Array<ProductResp>}
     * @memberof ListProductResp
     */
    data: Array<ProductResp>;
}

/**
 * Check if a given object implements the ListProductResp interface.
 */
export function instanceOfListProductResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "startIndex" in value;
    isInstance = isInstance && "endIndex" in value;
    isInstance = isInstance && "isMore" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListProductRespFromJSON(json: any): ListProductResp {
    return ListProductRespFromJSONTyped(json, false);
}

export function ListProductRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProductResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'startIndex': json['start_index'],
        'endIndex': json['end_index'],
        'isMore': json['is_more'],
        'data': ((json['data'] as Array<any>).map(ProductRespFromJSON)),
    };
}

export function ListProductRespToJSON(value?: ListProductResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'start_index': value.startIndex,
        'end_index': value.endIndex,
        'is_more': value.isMore,
        'data': ((value.data as Array<any>).map(ProductRespToJSON)),
    };
}

