/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpEmailResp
 */
export interface FollowUpEmailResp {
    /**
     * The UUID of the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    uuid: string;
    /**
     * The UUID of the client associated with the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    clientUuid: string;
    /**
     * The UUID of the outbound email associated with the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    outboundEmailUuid: string;
    /**
     * The body of the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    body: string;
    /**
     * The subject of the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    subject: string;
    /**
     * The status/current state of the follow up email
     * @type {string}
     * @memberof FollowUpEmailResp
     */
    status: FollowUpEmailRespStatusEnum;
    /**
     * The number of business days after the initial outbound email to send the follow up email.
     * @type {number}
     * @memberof FollowUpEmailResp
     */
    businessDaysAfterInitialEmail: number;
    /**
     * If in a SCHEDULED state, this will be set to the time it will be sent out. Otherwise null
     * @type {Date}
     * @memberof FollowUpEmailResp
     */
    scheduledAt?: Date;
    /**
     * The timestamp when the follow up email was last updated
     * @type {Date}
     * @memberof FollowUpEmailResp
     */
    lastUpdated: Date;
    /**
     * The timestamp when the follow up email was created
     * @type {Date}
     * @memberof FollowUpEmailResp
     */
    createdAt: Date;
}


/**
 * @export
 */
export const FollowUpEmailRespStatusEnum = {
    Pending: 'PENDING',
    Scheduled: 'SCHEDULED',
    Sent: 'SENT',
    Canceled: 'CANCELED'
} as const;
export type FollowUpEmailRespStatusEnum = typeof FollowUpEmailRespStatusEnum[keyof typeof FollowUpEmailRespStatusEnum];


/**
 * Check if a given object implements the FollowUpEmailResp interface.
 */
export function instanceOfFollowUpEmailResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "outboundEmailUuid" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "businessDaysAfterInitialEmail" in value;
    isInstance = isInstance && "lastUpdated" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function FollowUpEmailRespFromJSON(json: any): FollowUpEmailResp {
    return FollowUpEmailRespFromJSONTyped(json, false);
}

export function FollowUpEmailRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpEmailResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'clientUuid': json['client_uuid'],
        'outboundEmailUuid': json['outbound_email_uuid'],
        'body': json['body'],
        'subject': json['subject'],
        'status': json['status'],
        'businessDaysAfterInitialEmail': json['business_days_after_initial_email'],
        'scheduledAt': !exists(json, 'scheduled_at') ? undefined : (new Date(json['scheduled_at'])),
        'lastUpdated': (new Date(json['last_updated'])),
        'createdAt': (new Date(json['created_at'])),
    };
}

export function FollowUpEmailRespToJSON(value?: FollowUpEmailResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'client_uuid': value.clientUuid,
        'outbound_email_uuid': value.outboundEmailUuid,
        'body': value.body,
        'subject': value.subject,
        'status': value.status,
        'business_days_after_initial_email': value.businessDaysAfterInitialEmail,
        'scheduled_at': value.scheduledAt === undefined ? undefined : (value.scheduledAt.toISOString()),
        'last_updated': (value.lastUpdated.toISOString()),
        'created_at': (value.createdAt.toISOString()),
    };
}

