import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useState, useEffect } from "react";
import { Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";

const ComposeCard = ({
  emailSubject,
  emailBody,
  updateSendStatus,
  name,
  isPrimaryMessage,
  followUpEmailNumber,
  saveAndSendButtonClicked,
  messageUuid,
  handleSaveAndSendButtonClicked,
  outboundEmailUuid,
  clientUuid,
  dayOfSend,
}) => {
  // emailBodyState is the 'un-edited' generated email body. As users makes changes, emailBodyState updates.

  const [emailBodyState, setEmailBodyState] = useState(emailBody);

  // emailSubjectState is the 'un-edited' generated email subject line. As users makes changes, emailSubjectState updates.

  const [emailSubjectState, setEmailSubjectState] = useState(emailSubject);

  const handleEmailBodyChange = (updatedEmailBody) => {
    setEmailBodyState(updatedEmailBody);
  };

  const handleEmailSubjectChange = (event) => {
    setEmailSubjectState(event.target.value);
  };

  // TinyMCE adds HTML tags to text string. We want to remove that for the 'preview'
  function stripHtmlAndEntities(html) {
    return html
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/&nbsp;/g, " ")
      .trim();
  }

  useEffect(() => {
    if (saveAndSendButtonClicked) {
      let emailData;
      if (isPrimaryMessage) {
        emailData = {
          index: 0,
          clientUuid: clientUuid,
          messageUuid: messageUuid,
          emailBodyState: emailBodyState,
          emailSubjectState: emailSubjectState,
          isPrimaryMessage: true,
        };
        updateSendStatus(emailData);
      } else {
        emailData = {
          index: followUpEmailNumber,
          clientUuid: clientUuid,
          outboundEmailUuid: outboundEmailUuid,
          messageUuid: messageUuid,
          emailBodyState: emailBodyState,
          emailSubject: emailSubject,
          dayOfSend: dayOfSend,
          isPrimaryMessage: false,
        };
        updateSendStatus(emailData);
      }
    }
  }, [saveAndSendButtonClicked]);

  return (
    <Accordion
      defaultExpanded={isPrimaryMessage}
      style={{ marginBottom: !isPrimaryMessage ? "30px" : "0px" }}
    >
      {isPrimaryMessage ? (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          First Message for {name}
        </AccordionSummary>
      ) : (
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{ marginBottom: "5px" }}
              variant="h6"
            >{`Follow up email ${followUpEmailNumber}`}</Typography>
            <Typography
              style={{ marginBottom: "10px", fontSize: "small" }}
            >{`to be sent in: ${dayOfSend} days`}</Typography>
            <Typography variant="body1">
              {stripHtmlAndEntities(emailBodyState)}
            </Typography>
          </div>
        </AccordionSummary>
      )}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography
            sx={{ fontSize: 14, fontWeight: 800 }}
            color="black"
            gutterBottom
          >
            Message Content
          </Typography>

          {isPrimaryMessage && (
            <>
              <Typography
                sx={{ fontSize: 12, marginTop: "20px" }}
                color="text.secondary"
              >
                Email Subject
              </Typography>

              <TextField
                sx={{ marginTop: "15px", width: "100%" }}
                defaultValue={emailSubject}
                onChange={handleEmailSubjectChange}
              />
            </>
          )}

          <Typography
            sx={{ fontSize: 12, marginTop: "20px" }}
            color="text.secondary"
          >
            Email Body
          </Typography>
          <SunEditor
            defaultValue={emailBody}
            onChange={handleEmailBodyChange}
            setOptions={{
              minHeight: "300px",
              buttonList: [
                ["undo", "redo"],
                ["font", "fontSize"],
                ["bold", "underline", "italic"],
                ["fontColor", "hiliteColor"],
                ["outdent", "indent"],
                ["align", "list"],
                ["link"],
                ["removeFormat"],
              ],
            }}
          />
          {isPrimaryMessage && (
            <Button
              variant="contained"
              onClick={() => handleSaveAndSendButtonClicked()}
              sx={{ float: "right", marginBottom: "20px", marginTop: "15px" }}
            >
              Set and Send Messages
            </Button>
          )}
        </CardContent>
      </Card>
    </Accordion>
  );
};

export default ComposeCard;
