/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OutboundEmailResp } from './OutboundEmailResp';
import {
    OutboundEmailRespFromJSON,
    OutboundEmailRespFromJSONTyped,
    OutboundEmailRespToJSON,
} from './OutboundEmailResp';

/**
 * 
 * @export
 * @interface OutboundEmailListResp
 */
export interface OutboundEmailListResp {
    /**
     * The total number of outbound emails available
     * @type {number}
     * @memberof OutboundEmailListResp
     */
    count: number;
    /**
     * The start index of the retrieved outbound emails. 
     * This always increments by 1, to get the next result set just add one to this value
     * @type {number}
     * @memberof OutboundEmailListResp
     */
    startIndex: number;
    /**
     * Indicates if there are more outbound emails available
     * @type {boolean}
     * @memberof OutboundEmailListResp
     */
    isMore: boolean;
    /**
     * The array of outbound emails
     * @type {Array<OutboundEmailResp>}
     * @memberof OutboundEmailListResp
     */
    data: Array<OutboundEmailResp>;
}

/**
 * Check if a given object implements the OutboundEmailListResp interface.
 */
export function instanceOfOutboundEmailListResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "startIndex" in value;
    isInstance = isInstance && "isMore" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function OutboundEmailListRespFromJSON(json: any): OutboundEmailListResp {
    return OutboundEmailListRespFromJSONTyped(json, false);
}

export function OutboundEmailListRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutboundEmailListResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'startIndex': json['start_index'],
        'isMore': json['is_more'],
        'data': ((json['data'] as Array<any>).map(OutboundEmailRespFromJSON)),
    };
}

export function OutboundEmailListRespToJSON(value?: OutboundEmailListResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'start_index': value.startIndex,
        'is_more': value.isMore,
        'data': ((value.data as Array<any>).map(OutboundEmailRespToJSON)),
    };
}

