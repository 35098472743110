/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientTokenResp
 */
export interface ClientTokenResp {
    /**
     * Unique id for this client
     * @type {string}
     * @memberof ClientTokenResp
     */
    uuid: string;
    /**
     * the client uuid
     * @type {string}
     * @memberof ClientTokenResp
     */
    clientUuid: string;
    /**
     * The token balance for this client
     * @type {number}
     * @memberof ClientTokenResp
     */
    tokenBalance: number;
}

/**
 * Check if a given object implements the ClientTokenResp interface.
 */
export function instanceOfClientTokenResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "tokenBalance" in value;

    return isInstance;
}

export function ClientTokenRespFromJSON(json: any): ClientTokenResp {
    return ClientTokenRespFromJSONTyped(json, false);
}

export function ClientTokenRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTokenResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'clientUuid': json['client_uuid'],
        'tokenBalance': json['token_balance'],
    };
}

export function ClientTokenRespToJSON(value?: ClientTokenResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'client_uuid': value.clientUuid,
        'token_balance': value.tokenBalance,
    };
}

