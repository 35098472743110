import { OutboundEmailUpdateReqStatusEnum } from "../api-client";

export const sendOutboundEmail = async (
  clientUuid,
  outboundEmailUuid,
  emailBody,
  emailSubject,
  apiInstance
) => {
  try {
    const payload = {
      clientUuid: clientUuid,
      uuid: outboundEmailUuid,
      outboundEmailUpdateReq: {
        status: OutboundEmailUpdateReqStatusEnum.Sent,
        subject: emailSubject,
        content: emailBody,
        additionalTargets: {
          items: [],
        },
      },
    };

    const response = await apiInstance.updateOutboundEmail(payload);

    return response;
  } catch (error) {
    console.error("Error updating follow up email: ", error);
    return error;
  }
};
