/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProductResp
 */
export interface CreateProductResp {
    /**
     * Unique id for this client product record
     * @type {string}
     * @memberof CreateProductResp
     */
    uuid: string;
    /**
     * The client uuid
     * @type {string}
     * @memberof CreateProductResp
     */
    clientUuid: string;
    /**
     * The name of the client's product
     * @type {string}
     * @memberof CreateProductResp
     */
    name: string;
    /**
     * The description of the client's product
     * @type {string}
     * @memberof CreateProductResp
     */
    description: string;
    /**
     * The creation timestamp
     * @type {Date}
     * @memberof CreateProductResp
     */
    createdAt: Date;
    /**
     * The last updated timestamp
     * @type {Date}
     * @memberof CreateProductResp
     */
    lastUpdated: Date;
}

/**
 * Check if a given object implements the CreateProductResp interface.
 */
export function instanceOfCreateProductResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastUpdated" in value;

    return isInstance;
}

export function CreateProductRespFromJSON(json: any): CreateProductResp {
    return CreateProductRespFromJSONTyped(json, false);
}

export function CreateProductRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProductResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'clientUuid': json['client_uuid'],
        'name': json['name'],
        'description': json['description'],
        'createdAt': (new Date(json['created_at'])),
        'lastUpdated': (new Date(json['last_updated'])),
    };
}

export function CreateProductRespToJSON(value?: CreateProductResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'client_uuid': value.clientUuid,
        'name': value.name,
        'description': value.description,
        'created_at': (value.createdAt.toISOString()),
        'last_updated': (value.lastUpdated.toISOString()),
    };
}

