export const cancelFollowUpEmail = async (
  apiInstance,
  clientUuid,
  outboundEmailUuid,
  emailUuid
) => {
  try {
    const cancelFollowUpEmailResponse = await apiInstance.cancelFollowUpEmail({
      clientUuid: clientUuid,
      outboundEmailUuid: outboundEmailUuid,
      uuid: emailUuid,
    });
    return cancelFollowUpEmailResponse;
  } catch (error) {
    return error;
  }
};
