/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpEmailUpdateReq
 */
export interface FollowUpEmailUpdateReq {
    /**
     * The subject of the follow up email
     * @type {string}
     * @memberof FollowUpEmailUpdateReq
     */
    subject: string;
    /**
     * The body of the follow up email
     * @type {string}
     * @memberof FollowUpEmailUpdateReq
     */
    body: string;
    /**
     * The number of business days after the initial outbound email to send the follow up email.
     * Note that this can only be set if the follow up email is in a PENDING state, otherwise you will
     * get an error
     * @type {number}
     * @memberof FollowUpEmailUpdateReq
     */
    businessDaysAfterInitialEmail?: number;
    /**
     * If in a SCHEDULED state, this will be updated to the time it will be sent out. 
     * If the email is not in a SCHEDULED state and you set this field, you will get an error.
     * @type {Date}
     * @memberof FollowUpEmailUpdateReq
     */
    scheduledAt?: Date;
}

/**
 * Check if a given object implements the FollowUpEmailUpdateReq interface.
 */
export function instanceOfFollowUpEmailUpdateReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function FollowUpEmailUpdateReqFromJSON(json: any): FollowUpEmailUpdateReq {
    return FollowUpEmailUpdateReqFromJSONTyped(json, false);
}

export function FollowUpEmailUpdateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpEmailUpdateReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'body': json['body'],
        'businessDaysAfterInitialEmail': !exists(json, 'business_days_after_initial_email') ? undefined : json['business_days_after_initial_email'],
        'scheduledAt': !exists(json, 'scheduled_at') ? undefined : (new Date(json['scheduled_at'])),
    };
}

export function FollowUpEmailUpdateReqToJSON(value?: FollowUpEmailUpdateReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'body': value.body,
        'business_days_after_initial_email': value.businessDaysAfterInitialEmail,
        'scheduled_at': value.scheduledAt === undefined ? undefined : (value.scheduledAt.toISOString()),
    };
}

