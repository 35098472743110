import "./App.css";
import Login from "./components/Login";
import CreateAccount from "./components/CreateAccount";
import GoogleCallback from "./components/GoogleCallback";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import DataUpload from "./components/DataUpload";
import EmailChain from "./components/EmailChain";
import { createOutboundEmail } from "./utils/createOutboundEmail";
import { fetchDraftedEmails } from "./utils/fetchDraftedEmails";
import { fetchProduct } from "./utils/fetchProduct";
import { fetchSentEmails } from "./utils/fetchSentEmails";
import { cancelFollowUpEmail } from "./utils/cancelEmailChain";
import { getFollowUpEmails } from "./utils/getFollowUpEmails";
import { DefaultApi, Configuration } from "./api-client";
import { API_URL } from "./config/config";
import jwtDecode from "jwt-decode";

function App() {
  const token = sessionStorage.getItem("access_token");
  let decodedToken;
  let apiInstance;

  if (token) {
    decodedToken = jwtDecode(token);
    const apiConfig = new Configuration({
      basePath: API_URL,
      accessToken: token,
    });
    apiInstance = new DefaultApi(apiConfig);
  }

  const useAuth = () => {
    return token !== null;
  };

  const isAuthenticated = useAuth();

  function AuthenticatedApp() {
    return (
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Dashboard
              fetchDraftedEmails={fetchDraftedEmails}
              fetchProduct={fetchProduct}
            />
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/dataupload"
          element={<DataUpload createOutboundEmail={createOutboundEmail} />}
        />
        <Route
          path="/activeemailchains"
          element={
            <EmailChain
              apiInstance={apiInstance}
              fetchSentEmails={fetchSentEmails}
              cancelFollowUpEmail={cancelFollowUpEmail}
              getFollowUpEmails={getFollowUpEmails}
              token={token}
              decodedToken={decodedToken}
            />
          }
        />
        <Route path="/*" element={<Navigate to="/dashboard" />} />
      </Routes>
    );
  }

  function UnauthenticatedApp() {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }
  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </BrowserRouter>
  );
}

export default App;
