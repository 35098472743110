export const sendFollowUpEmail = async (
  clientUuid,
  outboundEmailUuid,
  messageUuid,
  emailBody,
  emailSubject,
  businessDaysAfterInitialEmail,
  apiInstance
) => {
  try {
    const payload = {
      clientUuid: clientUuid,
      outboundEmailUuid: outboundEmailUuid,
      uuid: messageUuid,
      followUpEmailUpdateReq: {
        subject: emailSubject,
        body: emailBody,
        businessDaysAfterInitialEmail: businessDaysAfterInitialEmail,
      },
    };

    const response = await apiInstance.updateFollowUpEmail(payload);

    return response;
  } catch (error) {
    console.error("Error updating follow up email: ", error);
    return error;
  }
};
