// set the limit to 100 for now, we can adjust this in the future if need be
import { ListOutboundEmailsStatusEnum } from "../api-client";

export const fetchDraftedEmails = async (apiInstance, clientUuid) => {
  const emailsFetchResponse = await apiInstance.listOutboundEmails({
    clientUuid: clientUuid,
    status: ListOutboundEmailsStatusEnum.Draft,
    limit: 100,
  });

  if (emailsFetchResponse && emailsFetchResponse.data) {
    return emailsFetchResponse.data;
  }
};
