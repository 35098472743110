const mockData = [
  {
    name: "Bob Jones",
    role: "VP of Accounting",
    company: "Acme Tech",
    foundInfo:
      "I'm baby franzen crucifix aesthetic selfies, viral kitsch marxism same brunch raw denim gatekeep four loko. Forage affogato viral, plaid offal taxidermy palo santo farm-to-table listicle. Yr grailed raw denim, paleo blue bottle vinyl hexagon. Man braid locavore kogi letterpress blog, vaporware grailed mlkshk offal raclette organic prism. Cold-pressed shabby chic squid adaptogen migas coloring book offal fixie brunch actually intelligentsia pug. VHS neutra bruh, portland four dollar toast pug 8-bit live-edge. Tumeric gluten-free biodiesel copper mug sartorial big mood try-hard.",
    emailSubject: "here is a fun test email subject 1",
    emailBody:
      "Godard tacos meditation sustainable yr kale chips. Sriracha scenester master cleanse pinterest echo park food truck put a bird on it prism tofu blackbird spyplane. Hexagon ascot mlkshk, try-hard austin iPhone live-edge chartreuse tousled literally migas 3 wolf moon man bun cred freegan. Echo park gatekeep fixie copper mug chambray vexillologist. Vape roof party pabst, vice locavore tofu photo booth PBR&B kickstarter helvetica freegan enamel pin ascot selvage. Adaptogen franzen chicharrones sriracha four dollar toast enamel pin shabby chic mumblecore edison bulb meditation.",
  },
  {
    name: "Steve Samuel",
    role: "VP of Finance",
    company: "Smile Tech",
    foundInfo:
      "I'm baby franzen crucifix aesthetic selfies, viral kitsch marxism same brunch raw denim gatekeep four loko. Forage affogato viral, plaid offal taxidermy palo santo farm-to-table listicle. Yr grailed raw denim, paleo blue bottle vinyl hexagon. Man braid locavore kogi letterpress blog, vaporware grailed mlkshk offal raclette organic prism. Cold-pressed shabby chic squid adaptogen migas coloring book offal fixie brunch actually intelligentsia pug. VHS neutra bruh, portland four dollar toast pug 8-bit live-edge. Tumeric gluten-free biodiesel copper mug sartorial big mood try-hard.",
    emailSubject: "here is a fun test email subject 2",
    emailBody:
      "Godard tacos meditation sustainable yr kale chips. Sriracha scenester master cleanse pinterest echo park food truck put a bird on it prism tofu blackbird spyplane. Hexagon ascot mlkshk, try-hard austin iPhone live-edge chartreuse tousled literally migas 3 wolf moon man bun cred freegan. Echo park gatekeep fixie copper mug chambray vexillologist. Vape roof party pabst, vice locavore tofu photo booth PBR&B kickstarter helvetica freegan enamel pin ascot selvage. Adaptogen franzen chicharrones sriracha four dollar toast enamel pin shabby chic mumblecore edison bulb meditation.",
  },
  {
    name: "Michael Jordan",
    role: "CEO",
    company: "Chicago Bulls",
    foundInfo:
      "I'm baby franzen crucifix aesthetic selfies, viral kitsch marxism same brunch raw denim gatekeep four loko. Forage affogato viral, plaid offal taxidermy palo santo farm-to-table listicle. Yr grailed raw denim, paleo blue bottle vinyl hexagon. Man braid locavore kogi letterpress blog, vaporware grailed mlkshk offal raclette organic prism. Cold-pressed shabby chic squid adaptogen migas coloring book offal fixie brunch actually intelligentsia pug. VHS neutra bruh, portland four dollar toast pug 8-bit live-edge. Tumeric gluten-free biodiesel copper mug sartorial big mood try-hard.",
    emailSubject: "here is a fun test email subject 3",
    emailBody:
      "Godard tacos meditation sustainable yr kale chips. Sriracha scenester master cleanse pinterest echo park food truck put a bird on it prism tofu blackbird spyplane. Hexagon ascot mlkshk, try-hard austin iPhone live-edge chartreuse tousled literally migas 3 wolf moon man bun cred freegan. Echo park gatekeep fixie copper mug chambray vexillologist. Vape roof party pabst, vice locavore tofu photo booth PBR&B kickstarter helvetica freegan enamel pin ascot selvage. Adaptogen franzen chicharrones sriracha four dollar toast enamel pin shabby chic mumblecore edison bulb meditation.",
  },
  {
    name: "Lionel Messi",
    role: "Staff Accountant",
    company: "Barcelona FC",
    foundInfo:
      "I'm baby franzen crucifix aesthetic selfies, viral kitsch marxism same brunch raw denim gatekeep four loko. Forage affogato viral, plaid offal taxidermy palo santo farm-to-table listicle. Yr grailed raw denim, paleo blue bottle vinyl hexagon. Man braid locavore kogi letterpress blog, vaporware grailed mlkshk offal raclette organic prism. Cold-pressed shabby chic squid adaptogen migas coloring book offal fixie brunch actually intelligentsia pug. VHS neutra bruh, portland four dollar toast pug 8-bit live-edge. Tumeric gluten-free biodiesel copper mug sartorial big mood try-hard.",
    emailSubject: "here is a fun test email subject 4",
    emailBody:
      "Godard tacos meditation sustainable yr kale chips. Sriracha scenester master cleanse pinterest echo park food truck put a bird on it prism tofu blackbird spyplane. Hexagon ascot mlkshk, try-hard austin iPhone live-edge chartreuse tousled literally migas 3 wolf moon man bun cred freegan. Echo park gatekeep fixie copper mug chambray vexillologist. Vape roof party pabst, vice locavore tofu photo booth PBR&B kickstarter helvetica freegan enamel pin ascot selvage. Adaptogen franzen chicharrones sriracha four dollar toast enamel pin shabby chic mumblecore edison bulb meditation.",
  },
];

const mockTabledata = {
  rows: [
    {
      id: 1,
      name: "Alice",
      role: "Developer",
      company: "TechCorp",
      dateCreated: "2022-01-01",
      isAdmin: true,
    },
    {
      id: 2,
      name: "Bob",
      role: "Manager",
      company: "BizInc",
      dateCreated: "2021-04-15",
      isAdmin: false,
    },
    {
      id: 3,
      name: "Charlie",
      role: "HR",
      company: "PeoplePlus",
      dateCreated: "2020-09-12",
      isAdmin: false,
    },
    {
      id: 4,
      name: "David",
      role: "Designer",
      company: "Artistic",
      dateCreated: "2023-05-20",
      isAdmin: false,
    },
    {
      id: 5,
      name: "Eve",
      role: "Analyst",
      company: "DataWorks",
      dateCreated: "2022-08-13",
      isAdmin: true,
    },
  ],
  columns: [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "role", headerName: "Role", width: 130 },
    { field: "company", headerName: "Company", width: 130 },
    { field: "dateCreated", headerName: "Date Created", width: 130 },
  ],
};

const mockRoles = [
  "CEO",
  "Accountant",
  "Chief of Staff",
  "General Counsel",
  "Attorney",
  "Janitor",
  "Admin Assistant",
  "CEO",
  "Accountant",
  "Chief of Staff",
  "General Counsel",
  "Attorney",
  "Janitor",
  "Admin Assistant",
  "CEO",
  "Accountant",
  "Chief of Staff",
  "General Counsel",
  "Attorney",
  "Janitor",
  "Admin Assistant",
];

const followUpsPerMessage = 7;
const remainingTokens = 1009;

export {
  mockData,
  mockRoles,
  followUpsPerMessage,
  remainingTokens,
  mockTabledata,
};
