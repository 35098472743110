import { Modal, Box, Typography, Button } from "@mui/material";
import React from "react";

const CancelEmailChainFailModal = ({ open, handleClose }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
      data-testid="error-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="error-modal-title" variant="h6" component="h2">
          Error
        </Typography>
        <Typography id="error-modal-description" sx={{ mt: 2 }}>
          There was an error in canceling this email chain. Please refresh the
          page and try again later.
        </Typography>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default CancelEmailChainFailModal;
