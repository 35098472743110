import { Button, Modal, Box, Typography, Link } from "@mui/material";

function ConsentModal({ open, handleConsentReject, handleConsentAgree }) {
  return (
    <Modal
      open={open}
      aria-labelledby="consent-modal-title"
      aria-describedby="consent-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          height: "auto",
          maxHeight: "calc(100% - 96px)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Typography
          id="consent-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontWeight: "bold" }}
        >
          Data Sharing Consent
        </Typography>
        <Typography id="consent-modal-description" sx={{ mt: 2 }}>
          Your privacy is important to us. We share some of your data with
          trusted third-party AI models to enable our services.
          <br />
          <br />
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            This includes:
          </Typography>
          <ul>
            <li>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Information you share about your product/service.
              </Typography>
            </li>
            <li>
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                Names, titles, emails of your prospective clients.
              </Typography>
            </li>
          </ul>
          To proceed with logging in, please review and accept the terms and
          conditions and privacy policy, which details how we share your data
          with third-party AI platforms to enable AutomatedSDR. By accepting the
          privacy policy and terms and conditions, you explicitly consent to the
          sharing of your data with these platforms. By clicking "I Agree," you
          consent to this data sharing.
          <br></br>
          <br></br>For more details, review our{" "}
          <Link
            href="https://www.automatedsdr.ai/#privacy-policy"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            href="https://www.automatedsdr.ai/#terms-and-conditions"
            target="_blank"
            rel="noopener"
          >
            Terms and Conditions
          </Link>
          .
        </Typography>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ backgroundColor: "red", color: "white" }}
            onClick={handleConsentReject}
          >
            Exit Application
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsentAgree}
          >
            I Agree
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConsentModal;
