import { Box, Container, Typography, Link as MuiLink } from "@mui/material";
// import { ReactComponent as GoogleLogo } from '../../../public/google.svg';
import { useEffect, useState } from "react";
import { DefaultApi, Configuration } from "../../api-client";
import { API_URL } from "../../config/config";

const GoogleCallback = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const [infoText, setInfoText] = useState<string>("");

  const storeAccessTokenInSessionStorage = (accessToken: string): void => {
    if (!window.sessionStorage) {
      throw new Error("Session storage is not supported in this browser.");
    }
    sessionStorage.setItem("access_token", accessToken);
  };
  const storeRefreshTokenInSessionStorage = (refreshToken: string): void => {
    if (!window.sessionStorage) {
      throw new Error("Session storage is not supported in this browser.");
    }
    sessionStorage.setItem("refresh_token", refreshToken);
  };

  useEffect(() => {
    const sendData = async () => {
      const currentURL = new URL(window.location.href);
      const protocol: string = currentURL.protocol;
      const host: string = currentURL.host;
      const path: string = currentURL.pathname;
      const queryParams: Map<string, string> = new Map(
        currentURL.searchParams.entries()
      );

      for (const [key, value] of queryParams.entries()) {
        console.log(`${key}: ${value}`);
      }
      const authorizationCode = queryParams.get("code");

      // Only proceed if the authorization code is available
      if (authorizationCode) {
        const apiConfig = new Configuration({
          basePath: API_URL,
        });

        const apiInstance = new DefaultApi(apiConfig);
        const requestBody = { authorizationCode: authorizationCode };

        try {
          const response = await apiInstance.oauth2LoginRaw({
            loginReq: requestBody,
          });

          const responseData = await response.value();

          if (responseData) {
            const { accessToken, refreshToken } = responseData;
            setResponseData(responseData);
            storeAccessTokenInSessionStorage(accessToken);
            storeRefreshTokenInSessionStorage(refreshToken);
            setInfoText(
              "Your login was successful. You will be redirected to The Dashboard"
            );
            window.location.href = "/dashboard";
          } else {
            setInfoText("Login Failed. Please Try again later.");

            console.log("fail");
            // Handle error
          }
        } catch (error) {
          console.log("error catch", error);
          // Handle network or request error
        }
      }
    };

    sendData();
  }, []);

  return (
    <div>
      <div>{infoText}</div>
      {responseData && (
        <div>
          <h2>Response Data:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default GoogleCallback;
