import style from "./ContextCard.module.css";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ContextCard = ({ name, role, company, foundInfo }) => {
  return (
    <div>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, fontWeight: 800, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Message Context
        </Typography>
      </CardContent>
      <Card sx={{ minWidth: 275 }} className={style.cardsParent}>
        <div className={style.cardContent}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Name: {name}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Role: {role}
            </Typography>

            <Typography
              sx={{ fontSize: 14, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Company: {company}
            </Typography>
          </CardContent>
        </div>
        <div className={style.cardContent}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Found Info:
              <br />
              <br />
              {foundInfo}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default ContextCard;
