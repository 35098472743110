/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailGenTemplateResp } from './EmailGenTemplateResp';
import {
    EmailGenTemplateRespFromJSON,
    EmailGenTemplateRespFromJSONTyped,
    EmailGenTemplateRespToJSON,
} from './EmailGenTemplateResp';

/**
 * 
 * @export
 * @interface EmailGenTemplateListResp
 */
export interface EmailGenTemplateListResp {
    /**
     * The array of email templates
     * @type {Array<EmailGenTemplateResp>}
     * @memberof EmailGenTemplateListResp
     */
    items: Array<EmailGenTemplateResp>;
}

/**
 * Check if a given object implements the EmailGenTemplateListResp interface.
 */
export function instanceOfEmailGenTemplateListResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function EmailGenTemplateListRespFromJSON(json: any): EmailGenTemplateListResp {
    return EmailGenTemplateListRespFromJSONTyped(json, false);
}

export function EmailGenTemplateListRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailGenTemplateListResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(EmailGenTemplateRespFromJSON)),
    };
}

export function EmailGenTemplateListRespToJSON(value?: EmailGenTemplateListResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(EmailGenTemplateRespToJSON)),
    };
}

