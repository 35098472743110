/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailGenTemplateResp
 */
export interface EmailGenTemplateResp {
    /**
     * The slug of the email template
     * @type {string}
     * @memberof EmailGenTemplateResp
     */
    slug: string;
    /**
     * The description of the email template
     * @type {string}
     * @memberof EmailGenTemplateResp
     */
    description: string;
}

/**
 * Check if a given object implements the EmailGenTemplateResp interface.
 */
export function instanceOfEmailGenTemplateResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function EmailGenTemplateRespFromJSON(json: any): EmailGenTemplateResp {
    return EmailGenTemplateRespFromJSONTyped(json, false);
}

export function EmailGenTemplateRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailGenTemplateResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': json['slug'],
        'description': json['description'],
    };
}

export function EmailGenTemplateRespToJSON(value?: EmailGenTemplateResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'description': value.description,
    };
}

