import NavBar from "../NavBar";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Button, Modal, Box, Typography, LinearProgress } from "@mui/material";
import styled from "@emotion/styled";
import { useState, useEffect } from "react";
import CancelEmailChainFailModal from "../CancelEmailChainFailModal";

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)`
  margin-top: 40px;
  padding: 50px;
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const buttonContainerStyle = {
  display: "flex",
  marginTop: "30px",
  gap: "8px",
};

const EmailChain = ({
  apiInstance,
  fetchSentEmails,
  cancelFollowUpEmail,
  getFollowUpEmails,
  decodedToken,
}) => {
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [emailDetails, setEmailDetails] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [openCancelEmailChainFailModal, setOpenCancelEmailChainFailModal] =
    useState(false);
  const clientUuid = decodedToken.ClientUUID;

  const handleButtonClick = (emailId) => {
    setConfirmCancelOpen(true);
    setSelectedEmailId(emailId); // Set the selected email ID when the button is clicked
    console.log(`Button clicked for email with ID: ${emailId}`);
  };

  const handleStopEmails = async () => {
    if (!selectedEmailId) return; // Ensure a selected email ID exists

    const selectedEmail = emailDetails.find(
      (email) => email.id === selectedEmailId
    );
    if (!selectedEmail) return; // Ensure the selected email is found

    try {
      for (const followUpEmail of selectedEmail.followUpEmails) {
        if (followUpEmail.status === "SCHEDULED") {
          await cancelFollowUpEmail(
            apiInstance,
            clientUuid,
            selectedEmail.id,
            followUpEmail.uuid
          );
        }
      }

      // Clear the emailDetails state
      setEmailDetails([]);
      setLoading(true);

      handleConfirmModalClose();

      // Re-fetch the email details to update the state
      await buildEmailDetailsObjects(apiInstance, clientUuid, setEmailDetails);
    } catch (error) {
      console.error("Error canceling follow-up email:", error);
      handleConfirmModalClose();
      handleOpenCancelEmailChainFailModal();
    }
  };

  const handleOpenCancelEmailChainFailModal = () => {
    setOpenCancelEmailChainFailModal(true);
  };
  const handleCloseCancelEmailChainFailModal = () => {
    setOpenCancelEmailChainFailModal(false);
  };

  const handleConfirmModalClose = () => {
    setConfirmCancelOpen(false);
    setSelectedEmailId(null); // Clear the selected email ID when the modal is closed
  };

  const fetchEmailBatch = async (start, batchSize) => {
    const sentEmailsResponse = await fetchSentEmails(apiInstance, clientUuid);
    return sentEmailsResponse.slice(start, start + batchSize);
  };

  const buildEmailDetailsObjects = async (
    apiInstance,
    clientUuid,
    setEmailDetails,
    batchSize = 10
  ) => {
    try {
      let start = 0;
      let allEmailsFetched = false;

      while (!allEmailsFetched) {
        const emailBatch = await fetchEmailBatch(start, batchSize);
        if (emailBatch.length === 0) {
          allEmailsFetched = true;
          if (start === 0) {
            setLoading(false);
          }
          break;
        }

        const detailedEmailObjects = [];

        for (const sentEmail of emailBatch) {
          const followUpResponse = await getFollowUpEmails(
            clientUuid,
            sentEmail.uuid,
            apiInstance
          );

          const hasScheduled = followUpResponse.data.some(
            (email) => email.status === "SCHEDULED"
          );

          detailedEmailObjects.push({
            id: sentEmail.uuid,
            toEmailAddress: sentEmail.toEmailAddress,
            followUpEmails: followUpResponse.data,
            hasScheduledFollowUp: hasScheduled,
          });
        }

        setEmailDetails((prevDetails) => [
          ...prevDetails,
          ...detailedEmailObjects,
        ]);
        start += batchSize;

        // Set loading to false after the first batch is fetched
        if (start === batchSize) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error building email details objects:", error);
      setLoading(false); // Set loading to false on error
    }
  };

  useEffect(() => {
    buildEmailDetailsObjects(apiInstance, clientUuid, setEmailDetails);
  }, []);

  return (
    <div>
      <NavBar />
      <div
        data-testid={"active-email-chain-view"}
        style={{ height: "100%", width: "100%" }}
      >
        {loading ? (
          <>
            <Typography
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "25px",
                textAlign: "center",
                paddingTop: "30px",
              }}
            >
              Loading Email Chains...
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <LinearProgress data-testid="linear-progress" />
            </div>
          </>
        ) : emailDetails.length === 0 ? (
          <Typography
            style={{
              fontWeight: "bold",
              color: "black",
              fontSize: "25px",
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            You haven't started any email chains
          </Typography>
        ) : (
          // Display DataGrid if loading is false and emailDetails is not empty
          <DataGrid
            columnBuffer={8}
            sx={{ padding: "2%" }}
            rows={emailDetails}
            columns={[
              {
                field: "toEmailAddress",
                headerName: "To Email Address",
                flex: 1,
              },
              {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                width: 220,
                renderCell: (params) => {
                  const hasScheduledFollowUp = params.row.hasScheduledFollowUp;
                  return (
                    <div aria-label={`stopEmailsButton-${params.row.id}`}>
                      {hasScheduledFollowUp ? (
                        <Button
                          variant="contained"
                          data-testid={`stopEmailsButton-${params.row.id}`}
                          role="button"
                          color="error"
                          onClick={() => handleButtonClick(params.row.id)}
                        >
                          Stop Emails
                        </Button>
                      ) : (
                        <Typography>Email Chain Completed</Typography>
                      )}
                    </div>
                  );
                },
              },
            ]}
            slots={{
              toolbar: StyledGridToolbarQuickFilter,
            }}
          />
        )}
      </div>
      <div aria-label="modal-container">
        <CancelEmailChainFailModal
          open={openCancelEmailChainFailModal}
          handleClose={handleCloseCancelEmailChainFailModal}
        />
        <Modal
          open={confirmCancelOpen}
          onClose={handleConfirmModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Remove prospect from the email chain?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Remove from email chain if the prospect has replied.
            </Typography>
            <Box sx={buttonContainerStyle}>
              <Button
                variant="contained"
                data-testid="modal-stop-emails-button"
                onClick={handleStopEmails}
                color="error"
              >
                Stop Emails
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirmModalClose}
                data-testid="cancel-button"
                sx={{ backgroundColor: "grey" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EmailChain;
