export const getFollowUpEmails = async (
  clientUuid,
  outboundEmailUuid,
  apiInstance
) => {
  try {
    const response = await apiInstance.listFollowUpEmails({
      clientUuid: clientUuid,
      outboundEmailUuid: outboundEmailUuid,
    });

    return response;
  } catch (error) {
    console.error("Error fetching follow-up emails: ", error);
    return error;
  }
};
