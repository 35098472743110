export const processEmails = async (
  emails,
  sendFollowUpEmail,
  sendOutboundEmail,
  setSaveAndSendButtonClicked,
  apiInstance
) => {
  try {
    const followUpEmailPromises = [];

    // Iterate over each email object
    for (const email of emails) {
      // If it's a follow-up email, push the promise of the API call to the array
      if (!email.isPrimaryMessage) {
        followUpEmailPromises.push(
          sendFollowUpEmail(
            email.clientUuid,
            email.outboundEmailUuid,
            email.messageUuid,
            email.emailBodyState,
            email.emailSubject,
            email.dayOfSend,
            apiInstance
          )
        );
      }
    }

    // Wait for all follow-up email promises to resolve
    await Promise.all(followUpEmailPromises);
  } catch (error) {
    console.error("Error sending follow-up emails:", error);
    setSaveAndSendButtonClicked(false);
    throw new Error("Failed sending one or more follow-up emails.");
  }

  try {
    // Once all follow-up emails are sent without errors, find the primary message and send it
    const primaryEmail = emails.find((email) => email.isPrimaryMessage);
    if (primaryEmail) {
      const response = await sendOutboundEmail(
        primaryEmail.clientUuid,
        primaryEmail.messageUuid,
        primaryEmail.emailBodyState,
        primaryEmail.emailSubjectState,
        apiInstance
      );
      console.log("Primary message sent:", response);
    }
  } catch (error) {
    console.error("Error sending primary email:", error);
    setSaveAndSendButtonClicked(false);
    throw new Error("Failed sending initial email message");
  }

  // Return true indicating that all emails were processed successfully
  return true;
};
