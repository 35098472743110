import style from "./Dashboard.module.css";
import NavBar from "../NavBar";
import ProductContextModal from "../ProductContextModal";
import ConsentModal from "../ConsentModal";
import { DefaultApi, Configuration } from "../../api-client";
import { API_URL } from "../../config/config";
import ProspectCard from "../ProspectCard";
import { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import {
  isTokenExpired,
  refreshTokens,
  redirectToLogin,
} from "../../utils/refreshTokens";
import { getFollowUpEmails } from "../../utils/getFollowUpEmails";
import { userLogOut } from "../../utils/userLogout";
import { processEmails } from "../../utils/processEmails";
import { sendOutboundEmail } from "../../utils/sendOutboundEmail";
import { sendFollowUpEmail } from "../../utils/sendFollowUpEmail";

const Dashboard = ({ fetchDraftedEmails, fetchProduct }) => {
  const [productCount, setProductCount] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(true);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(
    !localStorage.getItem("userConsent")
  );

  const [draftedEmails, setDraftedEmails] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const [errors, setErrors] = useState({});
  const decodedToken = jwtDecode(token);
  const clientUuid = decodedToken.ClientUUID;
  const draftedEmailsRef = useRef([]);
  const [allMessagesSent, setAllMessagesSent] = useState(false); // State to track if all messages are sent
  const [messageSentStatus, setMessageSentStatus] = useState({}); // State to track the sent status of each message

  const apiConfig = new Configuration({
    basePath: API_URL,
    accessToken: token,
  });

  const handleClose = () => {
    setIsProductModalOpen(false);
  };

  const handleConsentAgree = () => {
    localStorage.setItem("userConsent", "true");
    setIsConsentModalOpen(false);
  };

  const apiInstance = new DefaultApi(apiConfig);

  const intervalId = useRef(null);

  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const response = await fetchProduct(apiInstance, clientUuid);
        if (response && response.data) {
          setProductCount(response.count);
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fetchProductError: "Failed to fetch product count.",
        }));
      }
    };

    const fetchEmails = async () => {
      try {
        const emails = await fetchDraftedEmails(apiInstance, clientUuid);
        if (
          JSON.stringify(emails) !== JSON.stringify(draftedEmailsRef.current)
        ) {
          setDraftedEmails(emails);
          draftedEmailsRef.current = emails;
        } else {
          // Stop interval if email count hasn't changed
          clearInterval(intervalId.current);
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fetchEmailsError: "Failed to fetch emails.",
        }));
        clearInterval(intervalId.current);
      }
    };

    const init = async () => {
      try {
        // Refresh token if needed
        await refreshTokens(
          API_URL,
          apiInstance,
          isTokenExpired,
          redirectToLogin
        );

        // Continue with other operations if token is valid
        const currentToken = sessionStorage.getItem("access_token");
        if (currentToken) {
          await fetchProductCount();
          await fetchEmails();
          intervalId.current = setInterval(fetchEmails, 20000);
        }
      } catch (error) {
        console.error("Error initializing dashboard: ", error);
      }
    };

    init();
    // Clear interval on component unmount
    return () => clearInterval(intervalId.current);
  }, [token]);

  // Check if all messages are sent
  useEffect(() => {
    if (
      draftedEmails.length > 0 &&
      draftedEmails.every((email) => messageSentStatus[email.uuid])
    ) {
      setAllMessagesSent(true);
    } else {
      setAllMessagesSent(false); // Reset the state if there are still unsent messages
    }
  }, [draftedEmails, messageSentStatus]);

  const handleAllMessagesSent = (messageUuid) => {
    setMessageSentStatus((prevStatus) => ({
      ...prevStatus,
      [messageUuid]: true,
    }));
  };

  useEffect(() => {
    // Check if there are no drafted emails initially to show "You have no more messages"
    if (draftedEmails.length === 0) {
      setAllMessagesSent(true);
    }
  }, []);

  return (
    <div className={style.header} data-testid={"dashboard-view"}>
      <NavBar />
      {isConsentModalOpen ? (
        <ConsentModal
          open={isConsentModalOpen}
          handleConsentReject={userLogOut}
          handleConsentAgree={handleConsentAgree}
        />
      ) : (
        productCount === 0 && (
          <ProductContextModal
            open={isProductModalOpen}
            handleClose={handleClose}
            token={token}
          />
        )
      )}
      {Object.values(errors).length > 0 && (
        <div>
          {Object.entries(errors).map(([key, message]) => (
            <p key={key}>Error: {message}</p>
          ))}
        </div>
      )}
      {allMessagesSent ? (
        <p
          style={{
            fontWeight: "bold",
            color: "black",
            fontSize: "25px",
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          You have no more messages
        </p>
      ) : null}
      {Object.values(errors).length === 0 && (
        <>
          {draftedEmails?.length > 0 &&
            draftedEmails.map((prospect) => (
              <ProspectCard
                key={prospect.id}
                name={prospect.toEmailAddress}
                role={"Role goes here"}
                company={"Company goes here"}
                foundInfo={"Found info goes here"}
                emailBody={prospect.content.replace(/\n/g, "<br>")}
                emailSubject={prospect.subject}
                messageUuid={prospect.uuid}
                getFollowUpEmails={getFollowUpEmails}
                processEmails={processEmails}
                sendOutboundEmail={sendOutboundEmail}
                sendFollowUpEmail={sendFollowUpEmail}
                onAllMessagesSent={handleAllMessagesSent}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default Dashboard;
