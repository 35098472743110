/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalEmails } from './AdditionalEmails';
import {
    AdditionalEmailsFromJSON,
    AdditionalEmailsFromJSONTyped,
    AdditionalEmailsToJSON,
} from './AdditionalEmails';

/**
 * 
 * @export
 * @interface OutboundEmailResp
 */
export interface OutboundEmailResp {
    /**
     * The UUID of the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    uuid: string;
    /**
     * The UUID of the client associated with the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    clientUuid: string;
    /**
     * The UUID of the client employee associated with the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    clientEmployeeUuid: string;
    /**
     * The subject of the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    subject: string;
    /**
     * The recipient email address of the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    toEmailAddress: string;
    /**
     * 
     * @type {AdditionalEmails}
     * @memberof OutboundEmailResp
     */
    additionalTargets: AdditionalEmails;
    /**
     * The content of the outbound email
     * @type {string}
     * @memberof OutboundEmailResp
     */
    content: string;
    /**
     * The status of the outbound email (pending, draft, sent, or error)
     * @type {string}
     * @memberof OutboundEmailResp
     */
    status: OutboundEmailRespStatusEnum;
    /**
     * The timestamp when the outbound email was created
     * @type {Date}
     * @memberof OutboundEmailResp
     */
    createdAt: Date;
    /**
     * The timestamp when the outbound email was last updated
     * @type {Date}
     * @memberof OutboundEmailResp
     */
    lastUpdated: Date;
}


/**
 * @export
 */
export const OutboundEmailRespStatusEnum = {
    Pending: 'PENDING',
    Draft: 'DRAFT',
    Sent: 'SENT',
    Error: 'ERROR'
} as const;
export type OutboundEmailRespStatusEnum = typeof OutboundEmailRespStatusEnum[keyof typeof OutboundEmailRespStatusEnum];


/**
 * Check if a given object implements the OutboundEmailResp interface.
 */
export function instanceOfOutboundEmailResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "clientEmployeeUuid" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "toEmailAddress" in value;
    isInstance = isInstance && "additionalTargets" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastUpdated" in value;

    return isInstance;
}

export function OutboundEmailRespFromJSON(json: any): OutboundEmailResp {
    return OutboundEmailRespFromJSONTyped(json, false);
}

export function OutboundEmailRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutboundEmailResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'clientUuid': json['client_uuid'],
        'clientEmployeeUuid': json['client_employee_uuid'],
        'subject': json['subject'],
        'toEmailAddress': json['to_email_address'],
        'additionalTargets': json['additional_targets'],
        'content': json['content'],
        'status': json['status'],
        'createdAt': (new Date(json['created_at'])),
        'lastUpdated': (new Date(json['last_updated'])),
    };
}

export function OutboundEmailRespToJSON(value?: OutboundEmailResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'client_uuid': value.clientUuid,
        'client_employee_uuid': value.clientEmployeeUuid,
        'subject': value.subject,
        'to_email_address': value.toEmailAddress,
        'additional_targets': value.additionalTargets,
        'content': value.content,
        'status': value.status,
        'created_at': (value.createdAt.toISOString()),
        'last_updated': (value.lastUpdated.toISOString()),
    };
}

