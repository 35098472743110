/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientEmployeeResp } from './GetClientEmployeeResp';
import {
    GetClientEmployeeRespFromJSON,
    GetClientEmployeeRespFromJSONTyped,
    GetClientEmployeeRespToJSON,
} from './GetClientEmployeeResp';

/**
 * 
 * @export
 * @interface ListClientEmployeeResp
 */
export interface ListClientEmployeeResp {
    /**
     * The total count of client product records
     * @type {number}
     * @memberof ListClientEmployeeResp
     */
    count: number;
    /**
     * The index of the first record in the current result set
     * @type {number}
     * @memberof ListClientEmployeeResp
     */
    startIndex: number;
    /**
     * The index of the last record in the current result set
     * @type {number}
     * @memberof ListClientEmployeeResp
     */
    endIndex: number;
    /**
     * Indicates if more records are available
     * @type {boolean}
     * @memberof ListClientEmployeeResp
     */
    isMore: boolean;
    /**
     * 
     * @type {Array<GetClientEmployeeResp>}
     * @memberof ListClientEmployeeResp
     */
    data: Array<GetClientEmployeeResp>;
}

/**
 * Check if a given object implements the ListClientEmployeeResp interface.
 */
export function instanceOfListClientEmployeeResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "startIndex" in value;
    isInstance = isInstance && "endIndex" in value;
    isInstance = isInstance && "isMore" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListClientEmployeeRespFromJSON(json: any): ListClientEmployeeResp {
    return ListClientEmployeeRespFromJSONTyped(json, false);
}

export function ListClientEmployeeRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListClientEmployeeResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'startIndex': json['start_index'],
        'endIndex': json['end_index'],
        'isMore': json['is_more'],
        'data': ((json['data'] as Array<any>).map(GetClientEmployeeRespFromJSON)),
    };
}

export function ListClientEmployeeRespToJSON(value?: ListClientEmployeeResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'start_index': value.startIndex,
        'end_index': value.endIndex,
        'is_more': value.isMore,
        'data': ((value.data as Array<any>).map(GetClientEmployeeRespToJSON)),
    };
}

