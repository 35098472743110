import React from "react";
import { Modal, Button } from "@mui/material";

const OutOfTokensModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
      data-testId="out-of-tokens-modal"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          backgroundColor: "white",
          border: "2px solid #000",
          boxShadow: 24,
          padding: 16,
          textAlign: "center",
        }}
      >
        <h2 id="error-modal-title" data-testid="error-modal-title">
          Error
        </h2>
        <p id="error-modal-description">
          There was an error in creating your email chains.
          <br />
          <br />
          <br />
          <b>
            It is likely that you are out of email generation credits. Please
            contact your account manager and try again later.
          </b>
        </p>
        <br />
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default OutOfTokensModal;
