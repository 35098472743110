/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetClientEmployeeResp
 */
export interface GetClientEmployeeResp {
    /**
     * 
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    title?: string;
    /**
     * this field is used in at least scheduling follow ups, and in chaining emails together
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    timezone: GetClientEmployeeRespTimezoneEnum;
    /**
     * send mail as this email address instead of the default
     * @type {string}
     * @memberof GetClientEmployeeResp
     */
    sendMailAs?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClientEmployeeResp
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetClientEmployeeResp
     */
    lastUpdated?: Date;
}


/**
 * @export
 */
export const GetClientEmployeeRespTimezoneEnum = {
    NewYork: 'America__New_York',
    Chicago: 'America__Chicago',
    Denver: 'America__Denver',
    LosAngeles: 'America__Los_Angeles'
} as const;
export type GetClientEmployeeRespTimezoneEnum = typeof GetClientEmployeeRespTimezoneEnum[keyof typeof GetClientEmployeeRespTimezoneEnum];


/**
 * Check if a given object implements the GetClientEmployeeResp interface.
 */
export function instanceOfGetClientEmployeeResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "emailAddress" in value;
    isInstance = isInstance && "timezone" in value;

    return isInstance;
}

export function GetClientEmployeeRespFromJSON(json: any): GetClientEmployeeResp {
    return GetClientEmployeeRespFromJSONTyped(json, false);
}

export function GetClientEmployeeRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClientEmployeeResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'emailAddress': json['email_address'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'timezone': json['timezone'],
        'sendMailAs': !exists(json, 'send_mail_as') ? undefined : json['send_mail_as'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'lastUpdated': !exists(json, 'last_updated') ? undefined : (new Date(json['last_updated'])),
    };
}

export function GetClientEmployeeRespToJSON(value?: GetClientEmployeeResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'email_address': value.emailAddress,
        'name': value.name,
        'title': value.title,
        'timezone': value.timezone,
        'send_mail_as': value.sendMailAs,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'last_updated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
    };
}

