import styles from "./Settings.module.css";
import NavBar from "../NavBar";
import Card from "@mui/material/Card";
import {
  mockRoles,
  followUpsPerMessage,
  remainingTokens,
} from "../../mockData";
import {
  Button,
  CardContent,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
} from "@mui/material";

const Settings = () => {
  return (
    <div>
      <NavBar />
      <Card data-testid={"settings-page"} sx={{ minWidth: 275 }}>
        <CardContent>
          <div>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800, marginTop: "20px" }}
              color="black"
              gutterBottom
            >
              Remaining Tokens: {remainingTokens}
            </Typography>
            <Button variant="contained" sx={{ marginTop: "15px" }}>
              Buy More Tokens
            </Button>
          </div>
          <div className={styles.sectionContainer}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800, marginTop: "15px" }}
              color="black"
              gutterBottom
              fullWidth
            >
              Company Context:
            </Typography>

            <TextField
              sx={{ marginTop: "15px", width: "500px" }}
              id="outlined-multiline-flexible"
              multiline
              minRows={7}
            />
            <Typography
              sx={{ fontSize: 14, fontWeight: 800, marginTop: "20px" }}
              color="black"
              gutterBottom
            >
              Follow ups per message:
            </Typography>
            <TextField
              defaultValue={followUpsPerMessage}
              type="number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              sx={{ marginTop: "15px" }}
            />
          </div>
          <div className={styles.sectionContainer}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 800, marginTop: "20px" }}
              color="black"
              gutterBottom
            >
              Targeted Roles
            </Typography>
            <FormGroup class={styles.checkboxList}>
              {mockRoles.map((role) => {
                return <FormControlLabel control={<Checkbox />} label={role} />;
              })}
            </FormGroup>
          </div>
          <Button
            variant="contained"
            sx={{ float: "right", marginBottom: "20px", marginTop: "15px" }}
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Settings;
