/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOauth2RedirectUriResp
 */
export interface GetOauth2RedirectUriResp {
    /**
     * 
     * @type {string}
     * @memberof GetOauth2RedirectUriResp
     */
    redirectUri: string;
}

/**
 * Check if a given object implements the GetOauth2RedirectUriResp interface.
 */
export function instanceOfGetOauth2RedirectUriResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "redirectUri" in value;

    return isInstance;
}

export function GetOauth2RedirectUriRespFromJSON(json: any): GetOauth2RedirectUriResp {
    return GetOauth2RedirectUriRespFromJSONTyped(json, false);
}

export function GetOauth2RedirectUriRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOauth2RedirectUriResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectUri': json['redirect_uri'],
    };
}

export function GetOauth2RedirectUriRespToJSON(value?: GetOauth2RedirectUriResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirect_uri': value.redirectUri,
    };
}

