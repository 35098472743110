/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientEmployeeCreateReq,
  ClientResp,
  ClientTokenResp,
  ClientTokenUpdateReq,
  ConflictResponse,
  CreateClientReq,
  CreateOutboundEmailReq,
  CreateOutboundEmailResp,
  CreateProductReq,
  CreateProductResp,
  EmailGenTemplateListResp,
  FollowUpEmailListResp,
  FollowUpEmailResp,
  FollowUpEmailUpdateReq,
  GetClientEmployeeResp,
  GetOauth2RedirectUriResp,
  JwtCreateReq,
  ListClientEmployeeResp,
  ListProductResp,
  LoginReq,
  LoginResp,
  OutboundEmailListResp,
  OutboundEmailResp,
  OutboundEmailUpdateReq,
  RefreshTokenReq,
  SimulateEmailReq,
  SimulateEmailResp,
} from '../models/index';
import {
    ClientEmployeeCreateReqFromJSON,
    ClientEmployeeCreateReqToJSON,
    ClientRespFromJSON,
    ClientRespToJSON,
    ClientTokenRespFromJSON,
    ClientTokenRespToJSON,
    ClientTokenUpdateReqFromJSON,
    ClientTokenUpdateReqToJSON,
    ConflictResponseFromJSON,
    ConflictResponseToJSON,
    CreateClientReqFromJSON,
    CreateClientReqToJSON,
    CreateOutboundEmailReqFromJSON,
    CreateOutboundEmailReqToJSON,
    CreateOutboundEmailRespFromJSON,
    CreateOutboundEmailRespToJSON,
    CreateProductReqFromJSON,
    CreateProductReqToJSON,
    CreateProductRespFromJSON,
    CreateProductRespToJSON,
    EmailGenTemplateListRespFromJSON,
    EmailGenTemplateListRespToJSON,
    FollowUpEmailListRespFromJSON,
    FollowUpEmailListRespToJSON,
    FollowUpEmailRespFromJSON,
    FollowUpEmailRespToJSON,
    FollowUpEmailUpdateReqFromJSON,
    FollowUpEmailUpdateReqToJSON,
    GetClientEmployeeRespFromJSON,
    GetClientEmployeeRespToJSON,
    GetOauth2RedirectUriRespFromJSON,
    GetOauth2RedirectUriRespToJSON,
    JwtCreateReqFromJSON,
    JwtCreateReqToJSON,
    ListClientEmployeeRespFromJSON,
    ListClientEmployeeRespToJSON,
    ListProductRespFromJSON,
    ListProductRespToJSON,
    LoginReqFromJSON,
    LoginReqToJSON,
    LoginRespFromJSON,
    LoginRespToJSON,
    OutboundEmailListRespFromJSON,
    OutboundEmailListRespToJSON,
    OutboundEmailRespFromJSON,
    OutboundEmailRespToJSON,
    OutboundEmailUpdateReqFromJSON,
    OutboundEmailUpdateReqToJSON,
    RefreshTokenReqFromJSON,
    RefreshTokenReqToJSON,
    SimulateEmailReqFromJSON,
    SimulateEmailReqToJSON,
    SimulateEmailRespFromJSON,
    SimulateEmailRespToJSON,
} from '../models/index';

export interface CancelFollowUpEmailRequest {
    clientUuid: string;
    outboundEmailUuid: string;
    uuid: string;
}

export interface CreateClientRequest {
    createClientReq: CreateClientReq;
}

export interface CreateOutboundEmailRequest {
    clientUuid: string;
    uuid: string;
    createOutboundEmailReq: CreateOutboundEmailReq;
}

export interface CreateProductRequest {
    clientUuid: string;
    createProductReq: CreateProductReq;
}

export interface GetClientTokenBalanceRequest {
    clientUuid: string;
}

export interface GetOutboundEmailByUUIDRequest {
    clientUuid: string;
    uuid: string;
}

export interface IssueJwtRequest {
    jwtCreateReq: JwtCreateReq;
}

export interface ListClientEmployeesRequest {
    clientUuid: string;
}

export interface ListFollowUpEmailsRequest {
    clientUuid: string;
    outboundEmailUuid: string;
}

export interface ListOutboundEmailsRequest {
    clientUuid: string;
    status: ListOutboundEmailsStatusEnum;
    startIndex?: number;
    limit?: number;
}

export interface ListOutboundEmailsByClientAndEmployeeRequest {
    clientUuid: string;
    clientEmployeeUuid: string;
    status: ListOutboundEmailsByClientAndEmployeeStatusEnum;
    startIndex?: number;
    limit?: number;
}

export interface ListProductRequest {
    clientUuid: string;
    limit?: number;
    startIndex?: number;
}

export interface Oauth2LoginRequest {
    loginReq: LoginReq;
}

export interface Oauth2RefreshRequest {
    refreshTokenReq: RefreshTokenReq;
}

export interface PutClientEmployeeRequest {
    clientUuid: string;
    uuid: string;
    clientEmployeeCreateReq: ClientEmployeeCreateReq;
}

export interface SimulateEmailGenerationRequest {
    slug: string;
    simulateEmailReq: SimulateEmailReq;
}

export interface UpdateClientTokenBalanceRequest {
    clientUuid: string;
    clientTokenUpdateReq: ClientTokenUpdateReq;
}

export interface UpdateFollowUpEmailRequest {
    clientUuid: string;
    outboundEmailUuid: string;
    uuid: string;
    followUpEmailUpdateReq: FollowUpEmailUpdateReq;
}

export interface UpdateOutboundEmailRequest {
    clientUuid: string;
    uuid: string;
    outboundEmailUpdateReq: OutboundEmailUpdateReq;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Cancels a follow up email
     */
    async cancelFollowUpEmailRaw(requestParameters: CancelFollowUpEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling cancelFollowUpEmail.');
        }

        if (requestParameters.outboundEmailUuid === null || requestParameters.outboundEmailUuid === undefined) {
            throw new runtime.RequiredError('outboundEmailUuid','Required parameter requestParameters.outboundEmailUuid was null or undefined when calling cancelFollowUpEmail.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling cancelFollowUpEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{outbound-email-uuid}/follow-up-emails/{uuid}`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"outbound-email-uuid"}}`, encodeURIComponent(String(requestParameters.outboundEmailUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancels a follow up email
     */
    async cancelFollowUpEmail(requestParameters: CancelFollowUpEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelFollowUpEmailRaw(requestParameters, initOverrides);
    }

    /**
     * create and scaffold a client
     * create and scaffold a client
     */
    async createClientRaw(requestParameters: CreateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientResp>> {
        if (requestParameters.createClientReq === null || requestParameters.createClientReq === undefined) {
            throw new runtime.RequiredError('createClientReq','Required parameter requestParameters.createClientReq was null or undefined when calling createClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/client`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClientReqToJSON(requestParameters.createClientReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientRespFromJSON(jsonValue));
    }

    /**
     * create and scaffold a client
     * create and scaffold a client
     */
    async createClient(requestParameters: CreateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientResp> {
        const response = await this.createClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new outbound email for a client. Asynchronous kickoff, will  return status as PENDING and work in the background to make it a DRAFT 
     * Create a new outbound email using AI
     */
    async createOutboundEmailRaw(requestParameters: CreateOutboundEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOutboundEmailResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling createOutboundEmail.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling createOutboundEmail.');
        }

        if (requestParameters.createOutboundEmailReq === null || requestParameters.createOutboundEmailReq === undefined) {
            throw new runtime.RequiredError('createOutboundEmailReq','Required parameter requestParameters.createOutboundEmailReq was null or undefined when calling createOutboundEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{uuid}`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOutboundEmailReqToJSON(requestParameters.createOutboundEmailReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOutboundEmailRespFromJSON(jsonValue));
    }

    /**
     * Creates a new outbound email for a client. Asynchronous kickoff, will  return status as PENDING and work in the background to make it a DRAFT 
     * Create a new outbound email using AI
     */
    async createOutboundEmail(requestParameters: CreateOutboundEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOutboundEmailResp> {
        const response = await this.createOutboundEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * While this is not used now, this may in the future be used to allow the client to store arbitrary data about how their product helps 
     * Creates the product record, that a client wants to sell
     */
    async createProductRaw(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProductResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling createProduct.');
        }

        if (requestParameters.createProductReq === null || requestParameters.createProductReq === undefined) {
            throw new runtime.RequiredError('createProductReq','Required parameter requestParameters.createProductReq was null or undefined when calling createProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/product`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductReqToJSON(requestParameters.createProductReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProductRespFromJSON(jsonValue));
    }

    /**
     * While this is not used now, this may in the future be used to allow the client to store arbitrary data about how their product helps 
     * Creates the product record, that a client wants to sell
     */
    async createProduct(requestParameters: CreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProductResp> {
        const response = await this.createProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get the token balance for a client
     * get the token balance for a client
     */
    async getClientTokenBalanceRaw(requestParameters: GetClientTokenBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTokenResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling getClientTokenBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/token_balance`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTokenRespFromJSON(jsonValue));
    }

    /**
     * get the token balance for a client
     * get the token balance for a client
     */
    async getClientTokenBalance(requestParameters: GetClientTokenBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTokenResp> {
        const response = await this.getClientTokenBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of templates that generate emails from data
     */
    async getEmailGenTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailGenTemplateListResp>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email-generation-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailGenTemplateListRespFromJSON(jsonValue));
    }

    /**
     * Get a list of templates that generate emails from data
     */
    async getEmailGenTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailGenTemplateListResp> {
        const response = await this.getEmailGenTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the redirect uri for the app
     */
    async getOauth2RedirectUriRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOauth2RedirectUriResp>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/oauth2/redirect-uri`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOauth2RedirectUriRespFromJSON(jsonValue));
    }

    /**
     * Get the redirect uri for the app
     */
    async getOauth2RedirectUri(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOauth2RedirectUriResp> {
        const response = await this.getOauth2RedirectUriRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a specific outbound email by its UUID.
     * Get an outbound email by UUID
     */
    async getOutboundEmailByUUIDRaw(requestParameters: GetOutboundEmailByUUIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutboundEmailResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling getOutboundEmailByUUID.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getOutboundEmailByUUID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{uuid}`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutboundEmailRespFromJSON(jsonValue));
    }

    /**
     * Retrieves a specific outbound email by its UUID.
     * Get an outbound email by UUID
     */
    async getOutboundEmailByUUID(requestParameters: GetOutboundEmailByUUIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutboundEmailResp> {
        const response = await this.getOutboundEmailByUUIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns either an OK response code if the server is available or a 503 response code if it is not.
     * Health Check Endpoint
     */
    async healthGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns either an OK response code if the server is available or a 503 response code if it is not.
     * Health Check Endpoint
     */
    async healthGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.healthGetRaw(initOverrides);
    }

    /**
     * Issue a JWT, representing any client, purely for automated testing/internal use
     */
    async issueJwtRaw(requestParameters: IssueJwtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResp>> {
        if (requestParameters.jwtCreateReq === null || requestParameters.jwtCreateReq === undefined) {
            throw new runtime.RequiredError('jwtCreateReq','Required parameter requestParameters.jwtCreateReq was null or undefined when calling issueJwt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/admin/issue-jwt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JwtCreateReqToJSON(requestParameters.jwtCreateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginRespFromJSON(jsonValue));
    }

    /**
     * Issue a JWT, representing any client, purely for automated testing/internal use
     */
    async issueJwt(requestParameters: IssueJwtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResp> {
        const response = await this.issueJwtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all employees of the client
     */
    async listClientEmployeesRaw(requestParameters: ListClientEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListClientEmployeeResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling listClientEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client_uuid}/client_employee`.replace(`{${"client_uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListClientEmployeeRespFromJSON(jsonValue));
    }

    /**
     * Retrieve all employees of the client
     */
    async listClientEmployees(requestParameters: ListClientEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListClientEmployeeResp> {
        const response = await this.listClientEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of follow up emails associated with a specific outbound email.
     * Get a list of follow up emails for a given outbound email
     */
    async listFollowUpEmailsRaw(requestParameters: ListFollowUpEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FollowUpEmailListResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling listFollowUpEmails.');
        }

        if (requestParameters.outboundEmailUuid === null || requestParameters.outboundEmailUuid === undefined) {
            throw new runtime.RequiredError('outboundEmailUuid','Required parameter requestParameters.outboundEmailUuid was null or undefined when calling listFollowUpEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{outbound-email-uuid}/follow-up-email`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"outbound-email-uuid"}}`, encodeURIComponent(String(requestParameters.outboundEmailUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpEmailListRespFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of follow up emails associated with a specific outbound email.
     * Get a list of follow up emails for a given outbound email
     */
    async listFollowUpEmails(requestParameters: ListFollowUpEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FollowUpEmailListResp> {
        const response = await this.listFollowUpEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of outbound emails associated with a specific client.
     * Get a list of outbound emails for a client
     */
    async listOutboundEmailsRaw(requestParameters: ListOutboundEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutboundEmailListResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling listOutboundEmails.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listOutboundEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.startIndex !== undefined) {
            queryParameters['start_index'] = requestParameters.startIndex;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutboundEmailListRespFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of outbound emails associated with a specific client.
     * Get a list of outbound emails for a client
     */
    async listOutboundEmails(requestParameters: ListOutboundEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutboundEmailListResp> {
        const response = await this.listOutboundEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of outbound emails associated with a specific client employee.
     * Get a list of outbound emails for a client employee
     */
    async listOutboundEmailsByClientAndEmployeeRaw(requestParameters: ListOutboundEmailsByClientAndEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutboundEmailListResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling listOutboundEmailsByClientAndEmployee.');
        }

        if (requestParameters.clientEmployeeUuid === null || requestParameters.clientEmployeeUuid === undefined) {
            throw new runtime.RequiredError('clientEmployeeUuid','Required parameter requestParameters.clientEmployeeUuid was null or undefined when calling listOutboundEmailsByClientAndEmployee.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listOutboundEmailsByClientAndEmployee.');
        }

        const queryParameters: any = {};

        if (requestParameters.startIndex !== undefined) {
            queryParameters['start_index'] = requestParameters.startIndex;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/client-employee/{client-employee-uuid}/outbound-email`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"client-employee-uuid"}}`, encodeURIComponent(String(requestParameters.clientEmployeeUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutboundEmailListRespFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of outbound emails associated with a specific client employee.
     * Get a list of outbound emails for a client employee
     */
    async listOutboundEmailsByClientAndEmployee(requestParameters: ListOutboundEmailsByClientAndEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutboundEmailListResp> {
        const response = await this.listOutboundEmailsByClientAndEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Right now, this will always return one entry. Someday we may support many
     * list client product records
     */
    async listProductRaw(requestParameters: ListProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProductResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling listProduct.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.startIndex !== undefined) {
            queryParameters['start_index'] = requestParameters.startIndex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/product`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProductRespFromJSON(jsonValue));
    }

    /**
     * Right now, this will always return one entry. Someday we may support many
     * list client product records
     */
    async listProduct(requestParameters: ListProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProductResp> {
        const response = await this.listProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login to the system, after getting a redirect back from google (our only provider is google).  This will return a JWT token that can be used to authenticate future requests. The token will be valid for 24 hours to start with 
     * Login
     */
    async oauth2LoginRaw(requestParameters: Oauth2LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResp>> {
        if (requestParameters.loginReq === null || requestParameters.loginReq === undefined) {
            throw new runtime.RequiredError('loginReq','Required parameter requestParameters.loginReq was null or undefined when calling oauth2Login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oauth2/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginReqToJSON(requestParameters.loginReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginRespFromJSON(jsonValue));
    }

    /**
     * Login to the system, after getting a redirect back from google (our only provider is google).  This will return a JWT token that can be used to authenticate future requests. The token will be valid for 24 hours to start with 
     * Login
     */
    async oauth2Login(requestParameters: Oauth2LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResp> {
        const response = await this.oauth2LoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh the JWT token, if it is still valid.  This will return a new JWT token that can be used to authenticate future requests. The token will be valid for ? like a month we will say 
     * Refresh
     */
    async oauth2RefreshRaw(requestParameters: Oauth2RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResp>> {
        if (requestParameters.refreshTokenReq === null || requestParameters.refreshTokenReq === undefined) {
            throw new runtime.RequiredError('refreshTokenReq','Required parameter requestParameters.refreshTokenReq was null or undefined when calling oauth2Refresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oauth2/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenReqToJSON(requestParameters.refreshTokenReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginRespFromJSON(jsonValue));
    }

    /**
     * Refresh the JWT token, if it is still valid.  This will return a new JWT token that can be used to authenticate future requests. The token will be valid for ? like a month we will say 
     * Refresh
     */
    async oauth2Refresh(requestParameters: Oauth2RefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResp> {
        const response = await this.oauth2RefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the OpenAPI specification document.
     * OpenAPI Specification
     */
    async openapiYamlGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/openapi.yaml`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns the OpenAPI specification document.
     * OpenAPI Specification
     */
    async openapiYamlGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.openapiYamlGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a base client employee record. Auth is managed via /oauth2/login
     */
    async putClientEmployeeRaw(requestParameters: PutClientEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClientEmployeeResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling putClientEmployee.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling putClientEmployee.');
        }

        if (requestParameters.clientEmployeeCreateReq === null || requestParameters.clientEmployeeCreateReq === undefined) {
            throw new runtime.RequiredError('clientEmployeeCreateReq','Required parameter requestParameters.clientEmployeeCreateReq was null or undefined when calling putClientEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client_uuid}/client_employees/{uuid}`.replace(`{${"client_uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientEmployeeCreateReqToJSON(requestParameters.clientEmployeeCreateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClientEmployeeRespFromJSON(jsonValue));
    }

    /**
     * Update a base client employee record. Auth is managed via /oauth2/login
     */
    async putClientEmployee(requestParameters: PutClientEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClientEmployeeResp> {
        const response = await this.putClientEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Using the slug provided, simulate the generation of an email with stub data
     */
    async simulateEmailGenerationRaw(requestParameters: SimulateEmailGenerationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimulateEmailResp>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling simulateEmailGeneration.');
        }

        if (requestParameters.simulateEmailReq === null || requestParameters.simulateEmailReq === undefined) {
            throw new runtime.RequiredError('simulateEmailReq','Required parameter requestParameters.simulateEmailReq was null or undefined when calling simulateEmailGeneration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email-generation-templates/{slug}/simulate`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimulateEmailReqToJSON(requestParameters.simulateEmailReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimulateEmailRespFromJSON(jsonValue));
    }

    /**
     * Using the slug provided, simulate the generation of an email with stub data
     */
    async simulateEmailGeneration(requestParameters: SimulateEmailGenerationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimulateEmailResp> {
        const response = await this.simulateEmailGenerationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * credits or debits the client token balance
     */
    async updateClientTokenBalanceRaw(requestParameters: UpdateClientTokenBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientTokenResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling updateClientTokenBalance.');
        }

        if (requestParameters.clientTokenUpdateReq === null || requestParameters.clientTokenUpdateReq === undefined) {
            throw new runtime.RequiredError('clientTokenUpdateReq','Required parameter requestParameters.clientTokenUpdateReq was null or undefined when calling updateClientTokenBalance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/token_balance`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientTokenUpdateReqToJSON(requestParameters.clientTokenUpdateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTokenRespFromJSON(jsonValue));
    }

    /**
     * credits or debits the client token balance
     */
    async updateClientTokenBalance(requestParameters: UpdateClientTokenBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientTokenResp> {
        const response = await this.updateClientTokenBalanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a follow up email\'s schedule or content.
     */
    async updateFollowUpEmailRaw(requestParameters: UpdateFollowUpEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FollowUpEmailResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling updateFollowUpEmail.');
        }

        if (requestParameters.outboundEmailUuid === null || requestParameters.outboundEmailUuid === undefined) {
            throw new runtime.RequiredError('outboundEmailUuid','Required parameter requestParameters.outboundEmailUuid was null or undefined when calling updateFollowUpEmail.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateFollowUpEmail.');
        }

        if (requestParameters.followUpEmailUpdateReq === null || requestParameters.followUpEmailUpdateReq === undefined) {
            throw new runtime.RequiredError('followUpEmailUpdateReq','Required parameter requestParameters.followUpEmailUpdateReq was null or undefined when calling updateFollowUpEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{outbound-email-uuid}/follow-up-emails/{uuid}`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"outbound-email-uuid"}}`, encodeURIComponent(String(requestParameters.outboundEmailUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpEmailUpdateReqToJSON(requestParameters.followUpEmailUpdateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpEmailRespFromJSON(jsonValue));
    }

    /**
     * Update a follow up email\'s schedule or content.
     */
    async updateFollowUpEmail(requestParameters: UpdateFollowUpEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FollowUpEmailResp> {
        const response = await this.updateFollowUpEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an outbound email. This is typically used to send an email,  though it can also be used to update a draft. If the email is already sent, this will return a 400 
     */
    async updateOutboundEmailRaw(requestParameters: UpdateOutboundEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutboundEmailResp>> {
        if (requestParameters.clientUuid === null || requestParameters.clientUuid === undefined) {
            throw new runtime.RequiredError('clientUuid','Required parameter requestParameters.clientUuid was null or undefined when calling updateOutboundEmail.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateOutboundEmail.');
        }

        if (requestParameters.outboundEmailUpdateReq === null || requestParameters.outboundEmailUpdateReq === undefined) {
            throw new runtime.RequiredError('outboundEmailUpdateReq','Required parameter requestParameters.outboundEmailUpdateReq was null or undefined when calling updateOutboundEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{client-uuid}/outbound-email/{uuid}`.replace(`{${"client-uuid"}}`, encodeURIComponent(String(requestParameters.clientUuid))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OutboundEmailUpdateReqToJSON(requestParameters.outboundEmailUpdateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutboundEmailRespFromJSON(jsonValue));
    }

    /**
     * Update an outbound email. This is typically used to send an email,  though it can also be used to update a draft. If the email is already sent, this will return a 400 
     */
    async updateOutboundEmail(requestParameters: UpdateOutboundEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutboundEmailResp> {
        const response = await this.updateOutboundEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListOutboundEmailsStatusEnum = {
    Pending: 'PENDING',
    Draft: 'DRAFT',
    Sent: 'SENT',
    Error: 'ERROR'
} as const;
export type ListOutboundEmailsStatusEnum = typeof ListOutboundEmailsStatusEnum[keyof typeof ListOutboundEmailsStatusEnum];
/**
 * @export
 */
export const ListOutboundEmailsByClientAndEmployeeStatusEnum = {
    Pending: 'PENDING',
    Draft: 'DRAFT',
    Sent: 'SENT',
    Error: 'ERROR'
} as const;
export type ListOutboundEmailsByClientAndEmployeeStatusEnum = typeof ListOutboundEmailsByClientAndEmployeeStatusEnum[keyof typeof ListOutboundEmailsByClientAndEmployeeStatusEnum];
