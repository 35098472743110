import style from "./CreateAccount.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const CreateAccount = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div className={style.header}>
        <Typography variant="h4">Create Account</Typography>
      </div>
      <div className={style.textField}>
        <TextField id="outlined-basic" label="Email" variant="outlined" />
      </div>
      <div className={style.textField}>
        <TextField id="outlined-basic" label="Password" variant="outlined" />
      </div>
      <div className={style.textField}>
        <TextField
          id="outlined-basic"
          label="Confirm Password"
          variant="outlined"
        />
      </div>
      <div className={style.createAccountButton}>
        <Button variant="contained">Create Account</Button>
      </div>
    </Grid>
  );
};

export default CreateAccount;
