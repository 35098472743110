import { ListOutboundEmailsStatusEnum } from "../api-client";

export const fetchSentEmails = async (apiInstance, clientUuid) => {
  const emailsFetchResponse = await apiInstance.listOutboundEmails({
    clientUuid: clientUuid,
    status: ListOutboundEmailsStatusEnum.Sent,
    limit: 100,
  });

  if (emailsFetchResponse && emailsFetchResponse.data) {
    return emailsFetchResponse.data;
  }
};
