import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { Link } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { DefaultApi, Configuration } from "../../api-client";
import { API_URL } from "../../config/config";
import jwtDecode from "jwt-decode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ProductContextModal({ open, handleClose, token }) {
  const [productName, setProductName] = useState("");
  const [productContext, setProductContext] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const apiConfig = new Configuration({
    basePath: API_URL,
    accessToken: token,
  });

  const apiInstance = new DefaultApi(apiConfig);

  const handleProductContextChange = (event) => {
    const inputText = event.target.value;
    setProductContext(inputText);
  };

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleSave = async () => {
    try {
      const decodedToken = await jwtDecode(token);
      const clientUuid = decodedToken.ClientUUID;

      await apiInstance.createProduct({
        clientUuid: clientUuid,
        createProductReq: {
          uuid: clientUuid,
          name: productName,
          description: productContext,
        },
      });

      handleClose();
      window.location.href = "/dataupload";
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  useEffect(() => {
    setIsButtonDisabled(
      productContext.length <= 300 || productName.length <= 1
    );
  }, [productContext, productName]);

  return (
    <div data-testid="product-context-modal">
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h9" component="h2">
            Tell us about your product 🧠
            <br />
            <br />
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="h2">
            Welcome to AutomatedSDR!
            <br />
            <br />
            Before getting started, we need to learn about the product/service
            your organization provides.
            <br />
            <br />
            Please answer the questions in the text field below. On our end,
            more information is better.
            <br />
            <br />
            Pretend like you are explaining your business and industry to
            someone totally unfamiliar with the space.
            <br />
            <br />
            You can change this context anytime later within the 'Settings' tab.
            <List>
              <ListItem>
                <Typography
                  variant="body1"
                  component="div"
                  fontWeight="fontWeightBold"
                >
                  What does your product/service do?
                </Typography>
              </ListItem>
              <ListItem>
                <Typography
                  variant="body1"
                  component="div"
                  fontWeight="fontWeightBold"
                >
                  In which industry is your target customer?
                </Typography>
              </ListItem>
              <ListItem>
                <Typography
                  variant="body1"
                  component="div"
                  fontWeight="fontWeightBold"
                >
                  What are the benifits of using your product/service?
                </Typography>
              </ListItem>
            </List>
          </Typography>
          <Link href="google.com" variant="body2">
            Check out an example here!
          </Link>

          <TextField
            id="product-name"
            label="Product Name"
            variant="outlined"
            value={productName}
            onChange={handleProductNameChange}
            fullWidth
            margin="normal"
          />
          <TextField
            id="outlined-multiline-static"
            label="Product Context"
            multiline
            rows={4}
            value={productContext}
            onChange={handleProductContextChange}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isButtonDisabled}
            fullWidth
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ProductContextModal;
