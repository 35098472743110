import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import Typography from "@mui/material/Typography";

import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  textAlign: "center",
  boxShadow: 24,
  p: 4,
};

const DataUploadingModal = ({ isOpen }) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        window.location.href = "/dashboard";
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div data-testid="data-uploading-modal">
      <Modal
        open={isOpen}
        disableEscapeKeyDown
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h9" component="h2">
            We are generating your first 5 emails!
            <br />
            <br />
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="h2">
            After the first 5 emails are generated, you'll be redirected to the
            dashboard where you can edit/send messages.
            <br />
            <br />
            All emails after the first 5 will be generated in the background,
            and populate in your dashboard when complete 🙂
            <br />
            <br />
            This process may take up to 1 minute.
            <br />
            <br />
          </Typography>
          <LinearProgress data-testid="linear-progress-modal" />
        </Box>
      </Modal>
    </div>
  );
};

export default DataUploadingModal;
