/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOutboundEmailReq
 */
export interface CreateOutboundEmailReq {
    /**
     * 
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    toEmailAddress: string;
    /**
     * When provided, this will be the assumed company that is being targeted. 
     * When this is not provided, the suffix on `to_email_address` will be used
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    targetCompanyDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    role: string;
    /**
     * This will be the "to" name
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    name: string;
    /**
     * This will be the "from" email address
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    clientEmployeeUuid: string;
    /**
     * The slug representing the email template, see the list templates endpoint for options
     * @type {string}
     * @memberof CreateOutboundEmailReq
     */
    templateSlug?: string;
}

/**
 * Check if a given object implements the CreateOutboundEmailReq interface.
 */
export function instanceOfCreateOutboundEmailReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "toEmailAddress" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "clientEmployeeUuid" in value;

    return isInstance;
}

export function CreateOutboundEmailReqFromJSON(json: any): CreateOutboundEmailReq {
    return CreateOutboundEmailReqFromJSONTyped(json, false);
}

export function CreateOutboundEmailReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOutboundEmailReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toEmailAddress': json['to_email_address'],
        'targetCompanyDomain': !exists(json, 'target_company_domain') ? undefined : json['target_company_domain'],
        'role': json['role'],
        'name': json['name'],
        'clientEmployeeUuid': json['client_employee_uuid'],
        'templateSlug': !exists(json, 'template_slug') ? undefined : json['template_slug'],
    };
}

export function CreateOutboundEmailReqToJSON(value?: CreateOutboundEmailReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to_email_address': value.toEmailAddress,
        'target_company_domain': value.targetCompanyDomain,
        'role': value.role,
        'name': value.name,
        'client_employee_uuid': value.clientEmployeeUuid,
        'template_slug': value.templateSlug,
    };
}

