/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOutboundEmailResp
 */
export interface CreateOutboundEmailResp {
    /**
     * The UUID of the created outbound email
     * @type {string}
     * @memberof CreateOutboundEmailResp
     */
    uuid: string;
    /**
     * The Client UUID
     * @type {string}
     * @memberof CreateOutboundEmailResp
     */
    clientUuid: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOutboundEmailResp
     */
    toEmailAddress: string;
    /**
     * The slug representing the email template, see the list templates endpoint for options
     * @type {string}
     * @memberof CreateOutboundEmailResp
     */
    templateSlug: string;
    /**
     * The timestamp when the outbound email was created
     * @type {Date}
     * @memberof CreateOutboundEmailResp
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the CreateOutboundEmailResp interface.
 */
export function instanceOfCreateOutboundEmailResp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "toEmailAddress" in value;
    isInstance = isInstance && "templateSlug" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function CreateOutboundEmailRespFromJSON(json: any): CreateOutboundEmailResp {
    return CreateOutboundEmailRespFromJSONTyped(json, false);
}

export function CreateOutboundEmailRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOutboundEmailResp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'clientUuid': json['client_uuid'],
        'toEmailAddress': json['to_email_address'],
        'templateSlug': json['template_slug'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function CreateOutboundEmailRespToJSON(value?: CreateOutboundEmailResp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'client_uuid': value.clientUuid,
        'to_email_address': value.toEmailAddress,
        'template_slug': value.templateSlug,
        'created_at': (value.createdAt.toISOString()),
    };
}

