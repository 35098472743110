/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalEmails } from './AdditionalEmails';
import {
    AdditionalEmailsFromJSON,
    AdditionalEmailsFromJSONTyped,
    AdditionalEmailsToJSON,
} from './AdditionalEmails';

/**
 * 
 * @export
 * @interface OutboundEmailUpdateReq
 */
export interface OutboundEmailUpdateReq {
    /**
     * The status of the outbound email (draft, sent).
     * Note that SENT is a terminal status, and the email can't be updated after that transition.
     * @type {string}
     * @memberof OutboundEmailUpdateReq
     */
    status: OutboundEmailUpdateReqStatusEnum;
    /**
     * The subject of the outbound email
     * @type {string}
     * @memberof OutboundEmailUpdateReq
     */
    subject: string;
    /**
     * The content of the outbound email
     * @type {string}
     * @memberof OutboundEmailUpdateReq
     */
    content: string;
    /**
     * 
     * @type {AdditionalEmails}
     * @memberof OutboundEmailUpdateReq
     */
    additionalTargets?: AdditionalEmails;
}


/**
 * @export
 */
export const OutboundEmailUpdateReqStatusEnum = {
    Draft: 'DRAFT',
    Sent: 'SENT'
} as const;
export type OutboundEmailUpdateReqStatusEnum = typeof OutboundEmailUpdateReqStatusEnum[keyof typeof OutboundEmailUpdateReqStatusEnum];


/**
 * Check if a given object implements the OutboundEmailUpdateReq interface.
 */
export function instanceOfOutboundEmailUpdateReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function OutboundEmailUpdateReqFromJSON(json: any): OutboundEmailUpdateReq {
    return OutboundEmailUpdateReqFromJSONTyped(json, false);
}

export function OutboundEmailUpdateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutboundEmailUpdateReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'subject': json['subject'],
        'content': json['content'],
        'additionalTargets': !exists(json, 'additional_targets') ? undefined : json['additional_targets'],
    };
}

export function OutboundEmailUpdateReqToJSON(value?: OutboundEmailUpdateReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'subject': value.subject,
        'content': value.content,
        'additional_targets': value.additionalTargets,
    };
}

