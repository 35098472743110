/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateClientReq
 */
export interface CreateClientReq {
    /**
     * Unique id for this client
     * @type {string}
     * @memberof CreateClientReq
     */
    uuid: string;
    /**
     * The name of the client
     * @type {string}
     * @memberof CreateClientReq
     */
    name: string;
    /**
     * The domain name the client can be found atf
     * @type {string}
     * @memberof CreateClientReq
     */
    companyUrl: string;
}

/**
 * Check if a given object implements the CreateClientReq interface.
 */
export function instanceOfCreateClientReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyUrl" in value;

    return isInstance;
}

export function CreateClientReqFromJSON(json: any): CreateClientReq {
    return CreateClientReqFromJSONTyped(json, false);
}

export function CreateClientReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClientReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'companyUrl': json['company_url'],
    };
}

export function CreateClientReqToJSON(value?: CreateClientReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'company_url': value.companyUrl,
    };
}

