import NavBar from "../NavBar";
import Card from "@mui/material/Card";
import CSVReader from "react-csv-reader";
import validator from "validator";
import { useState, useEffect } from "react";
import {
  Button,
  CardContent,
  Typography,
  Alert,
  LinearProgress,
} from "@mui/material";
import { DefaultApi, Configuration } from "../../api-client";
import { API_URL } from "../../config/config";
import jwtDecode from "jwt-decode";
import DataUploadingModal from "../DataUploadingModal";
import OutOfTokensModal from "../OutOfTokensModal";

const DataUpload = ({ createOutboundEmail }) => {
  // State variable for validated CSV
  const [validatedCsv, setValidatedCsv] = useState([]);
  const [errorRows, setErrorRows] = useState([]);
  const [loadingBarOpen, setLoadingBarOpen] = useState(false);
  const [isEmailGenerationModalOpen, setIsEmailGenerationModalOpen] =
    useState(false);
  const [isOutOfTokensModalOpen, setIsOutOfTokensModalOpen] = useState(false);
  const token = sessionStorage.getItem("access_token");
  const decodedToken = jwtDecode(token);
  const clientUuid = decodedToken.ClientUUID;
  const clientEmployeeUuid = decodedToken.ClientEmployeeUUID;

  const apiConfig = new Configuration({
    basePath: API_URL,
    accessToken: token,
  });

  const apiInstance = new DefaultApi(apiConfig);

  const uploadButtonClick = async () => {
    setLoadingBarOpen(true);

    // Array to track failed rows for retry
    let failedRows = [];

    for (let i = 0; i < validatedCsv.length; i++) {
      const row = validatedCsv[i];
      const isSuccess = await createOutboundEmail(
        row,
        apiInstance,
        clientUuid,
        clientEmployeeUuid
      );
      if (!isSuccess) {
        failedRows.push(row);
      }
    }

    // retry 2x for failed rows
    let finalFailedRows = [];
    for (const row of failedRows) {
      const isSuccess = await createOutboundEmail(
        row,
        apiInstance,
        clientUuid,
        clientEmployeeUuid
      );
      if (!isSuccess) {
        const secondTrySuccess = await createOutboundEmail(
          row,
          apiInstance,
          clientUuid,
          clientEmployeeUuid
        );
        if (!secondTrySuccess) {
          finalFailedRows.push(row);
        }
      }
    }

    // Log final failed rows to console after 2x retries
    if (finalFailedRows.length > 0) {
      console.log(
        "The following rows failed after two retries:",
        finalFailedRows
      );
      handleOutOfTokensModalOpen();
      return;
      // i may need a return here to break out of the funciton
    }

    console.log("here is the csv", validatedCsv);

    setIsEmailGenerationModalOpen(true);

    // Simulate loading for 2 seconds
    setTimeout(() => {
      setLoadingBarOpen(false);
    }, 2000);
  };

  const validateCsvFile = (data) => {
    // Allowing alphabetical chars, and spaces for the Name field.
    const nameRegex = /^[A-Za-z\s]+$/;
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [headerRow, ...rows] = data;

    // "targetCompanyDomain" is optional, so it's not included in requiredFields
    const requiredFields = ["Name", "Role", "Email", "BCC Email"];

    // Initial check for required header fields
    if (
      headerRow.length >= requiredFields.length &&
      requiredFields.every((field) => headerRow.includes(field))
    ) {
      console.log("Header is valid for required fields");
    } else {
      console.log("Header row is not valid for required fields.");
      setErrorRows((prevErrorRows) =>
        prevErrorRows.concat({
          rowIndex: 0 + 1,
          errors: [
            "There is a syntax error in the required fields of the header row of your CSV",
          ],
        })
      );
      return;
    }

    let validatedData = []; // Array to hold objects representing validated rows
    let errorRowsLocal = []; // Temporary array to collect error rows

    // Process the data in each row
    rows.forEach((row, rowIndex) => {
      // Check if the row is completely blank
      if (row.every((cell) => cell.trim() === "")) {
        // If the row is blank, skip and don't include in rowData
        return;
      }
      let rowData = {};
      headerRow.forEach((columnName, columnIndex) => {
        rowData[columnName] = row[columnIndex] || ""; // Use empty string for missing values
      });

      let errors = []; // Collect errors for the current row

      // Validate Name
      if (!nameRegex.test(rowData["Name"])) {
        errors.push(
          `Name contains non-alphabetical characters on row ${rowIndex + 2}`
        );
      }

      // Validate Email
      if (!validator.isEmail(rowData["Email"])) {
        errors.push(`Invalid Email on row ${rowIndex + 2}`);
      }

      // Validate BCC Email
      if (!validator.isEmail(rowData["BCC Email"])) {
        errors.push(`Invalid BCC Email on row ${rowIndex + 2}`);
      }

      // Validate targetCompanyDomain if present
      if (
        rowData["targetCompanyDomain"] &&
        !domainRegex.test(rowData["targetCompanyDomain"])
      ) {
        errors.push(`Invalid targetCompanyDomain on row ${rowIndex + 2}`);
      }

      // If there are errors, add this row's errors to the collection
      if (errors.length > 0) {
        errorRowsLocal.push({ rowIndex: rowIndex + 2, errors });
      } else {
        validatedData.push(rowData);
      }
    });

    // Update state with collected errors
    setErrorRows(errorRowsLocal);

    // Update validated CSV data if no errors found
    if (errorRowsLocal.length === 0) {
      setValidatedCsv(validatedData); // Use validatedData instead of the original data array
      console.log("this csv is valid", validatedData);
    } else {
      console.log(
        "Errors found in CSV. Please correct the following errors:",
        errorRowsLocal
      );
      setValidatedCsv([]); // Clear validatedCsv if errors exist
    }
  };

  const handleCsvUpload = (data, fileInfo) => {
    validateCsvFile(data);
  };

  const handleOutOfTokensModalOpen = () => {
    setIsOutOfTokensModalOpen(true);
    setLoadingBarOpen(false);
  };

  const handleOutOfTokensModalClose = () => {
    setIsOutOfTokensModalOpen(false);
  };

  useEffect(() => {
    if (errorRows.length > 0) {
      console.log("There were errors, here are the Error Rows:", errorRows);
      return;
    } else {
      console.log(
        "success! no errors in your csv! Here is the validated csv: ",
        validatedCsv
      );
    }
  }, [errorRows, validatedCsv]);

  return (
    <div>
      <NavBar />
      <DataUploadingModal isOpen={isEmailGenerationModalOpen} />
      <OutOfTokensModal
        open={isOutOfTokensModalOpen}
        onClose={handleOutOfTokensModalClose}
      />
      <Card data-testid={"data-upload-view"} sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 800,
              marginTop: "60px",
              marginBottom: "40px",
            }}
            color="black"
            gutterBottom
          >
            Data Upload
          </Typography>
          <div aria-label="CSV Upload Container">
            <CSVReader onFileLoaded={handleCsvUpload} />
          </div>
          {errorRows.length > 0 ? (
            <Alert severity="error">
              Your CSV has the following errors, please resolve:
              <ul>
                {errorRows.map((errorRow, index) => (
                  <li key={index}>
                    Row {errorRow.rowIndex}: {errorRow.errors.join(", ")}
                  </li>
                ))}
              </ul>
            </Alert>
          ) : null}
          {loadingBarOpen && (
            <div
              aria-label="Loading-Bar-Container"
              style={{ marginTop: "20px" }}
            >
              <Typography>Loading...</Typography>
              <LinearProgress data-testid="linear-progress" />
            </div>
          )}
          <Button
            onClick={uploadButtonClick}
            disabled={
              errorRows.length > 0 ||
              validatedCsv.length === 0 ||
              loadingBarOpen
            }
            variant="contained"
            sx={{ float: "right", marginBottom: "20px", marginTop: "15px" }}
          >
            Upload
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default DataUpload;
