import jwtDecode from "jwt-decode";

export const redirectToLogin = () => {
  window.location = "/login";
};

// Helper function to check if a token is expired
export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const now = Date.now().valueOf() / 1000;
    return typeof decoded.exp !== "undefined" && decoded.exp < now;
  } catch (err) {
    return false; // if token is invalid or can't be decoded, treat it as expired for safety
  }
};

export const refreshTokens = async (
  apiUrl,
  apiInstance,
  isTokenExpired,
  redirectFunction
) => {
  // On all requests requiring access_token, if response is 401, run call this funciton and re-run request.
  const storedAccessToken = sessionStorage.getItem("access_token");
  const storedRefreshToken = sessionStorage.getItem("refresh_token");

  // If no token, handle that (unlikely)
  if (!storedRefreshToken) {
    console.error("No refresh token found in session storage.");
    return;
  }

  const payload = {
    refreshToken: storedRefreshToken,
  };

  if (isTokenExpired(storedAccessToken)) {
    if (isTokenExpired(storedRefreshToken)) {
      // Remove the tokens and redirect to login
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("refresh_token");
      redirectFunction();
    } else {
      // Call oauth2Refresh to get a new set of tokens
      try {
        const newTokens = await apiInstance.oauth2Refresh({
          refreshTokenReq: payload,
        });

        sessionStorage.setItem("access_token", newTokens.accessToken);
        sessionStorage.setItem("refresh_token", newTokens.refreshToken);
      } catch (error) {
        console.error("Failed to refresh token: ", error);
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        redirectFunction();
      }
    }
  }
};
