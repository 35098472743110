/* tslint:disable */
/* eslint-disable */
/**
 * AutoSDR
 * automated sdr server
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JwtCreateReq
 */
export interface JwtCreateReq {
    /**
     * 
     * @type {string}
     * @memberof JwtCreateReq
     */
    clientUuid: string;
    /**
     * 
     * @type {string}
     * @memberof JwtCreateReq
     */
    clientEmployeeUuid: string;
    /**
     * 
     * @type {string}
     * @memberof JwtCreateReq
     */
    emailAddress: string;
}

/**
 * Check if a given object implements the JwtCreateReq interface.
 */
export function instanceOfJwtCreateReq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientUuid" in value;
    isInstance = isInstance && "clientEmployeeUuid" in value;
    isInstance = isInstance && "emailAddress" in value;

    return isInstance;
}

export function JwtCreateReqFromJSON(json: any): JwtCreateReq {
    return JwtCreateReqFromJSONTyped(json, false);
}

export function JwtCreateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtCreateReq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientUuid': json['client_uuid'],
        'clientEmployeeUuid': json['client_employee_uuid'],
        'emailAddress': json['email_address'],
    };
}

export function JwtCreateReqToJSON(value?: JwtCreateReq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_uuid': value.clientUuid,
        'client_employee_uuid': value.clientEmployeeUuid,
        'email_address': value.emailAddress,
    };
}

