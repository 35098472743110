import { v4 as uuidv4 } from "uuid";

export const createOutboundEmail = async (
  row,
  apiInstance,
  clientUuid,
  clientEmployeeUuid
) => {
  try {
    const messageUuid = uuidv4();

    let createOutboundEmailReq = {
      clientUuid: clientUuid,
      uuid: messageUuid,
      createOutboundEmailReq: {
        toEmailAddress: row["Email"],
        role: row["Role"],
        name: row["Name"],
        clientEmployeeUuid: clientEmployeeUuid,
        // Conditionally include targetCompanyDomain only if it exists and is not an empty string
        ...(row["targetCompanyDomain"] &&
          row["targetCompanyDomain"].trim() !== "" && {
            targetCompanyDomain: row["targetCompanyDomain"],
          }),
      },
    };

    const response = await apiInstance.createOutboundEmail(
      createOutboundEmailReq
    );
    console.log("Email creation successful:", response);
    return true;
  } catch (error) {
    console.error("API call failed: ", error);
    return false;
  }
};
