import { useState, useEffect } from "react";
import { DefaultApi, Configuration } from "../../api-client";
import ContextCard from "../ContextCard";
import ComposeCard from "../ComposeCard";
import { API_URL } from "../../config/config";
import jwtDecode from "jwt-decode";
import SendFailModal from "../SendFailModal";

const ProspectCard = ({
  name,
  role,
  company,
  foundInfo,
  emailSubject,
  emailBody,
  messageUuid,
  getFollowUpEmails,
  processEmails,
  sendFollowUpEmail,
  sendOutboundEmail,
  onAllMessagesSent, // Receive callback function
}) => {
  const [followUpEmails, setFollowUpEmails] = useState([]);
  // saveAndSendButtonClicked communicates to the parent that the button has been clicked.
  const [saveAndSendButtonClicked, setSaveAndSendButtonClicked] =
    useState(false);
  const [updatedEmails, setUpdatedEmails] = useState([]);
  const [shouldHide, setShouldHide] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const token = sessionStorage.getItem("access_token");
  const decodedToken = jwtDecode(token);

  // Function to open the error modal
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };

  // Function to close the error modal
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  const apiConfig = new Configuration({
    basePath: API_URL,
    accessToken: token,
  });

  const apiInstance = new DefaultApi(apiConfig);

  const handleSaveAndSendButtonClicked = () => {
    setSaveAndSendButtonClicked(true);
  };

  const updateSendStatus = (emailData) => {
    setUpdatedEmails((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[emailData.index] = emailData;

      console.log("updatedEmails:", newStatuses);
      return newStatuses;
    });
  };

  useEffect(() => {
    if (saveAndSendButtonClicked && updatedEmails.length === 3) {
      processEmails(
        updatedEmails,
        sendFollowUpEmail,
        sendOutboundEmail,
        setSaveAndSendButtonClicked,
        apiInstance
      )
        .then((success) => {
          if (success) {
            // If all emails are processed successfully, set shouldHide to true
            setShouldHide(true);
            onAllMessagesSent(messageUuid); // Call the callback function
          }
        })
        .catch((error) => {
          console.error("Error processing emails:", error);
          //open error modal if any error
          handleOpenErrorModal();
          //clear state, so api calls don't send 2x
          setUpdatedEmails([]);
        });
    }
  }, [saveAndSendButtonClicked, updatedEmails]);

  useEffect(() => {
    const fetchDataSetState = async () => {
      try {
        const response = await getFollowUpEmails(
          decodedToken.ClientUUID,
          messageUuid,
          apiInstance
        );

        if (response && response.data) {
          setFollowUpEmails(response.data);
        } else {
          console.error("No follow-up emails found.");
        }
      } catch (error) {
        console.error("Error in fetchDataSetState: ", error);
      }
    };
    if (messageUuid) {
      fetchDataSetState();
    }
  }, [messageUuid, decodedToken.ClientUUID]);

  return (
    <div>
      <SendFailModal open={errorModalOpen} onClose={handleCloseErrorModal} />
      {shouldHide ? null : (
        <div
          style={{ borderBottom: "20px solid #D35F65", paddingBottom: "10px" }}
        >
          <ContextCard
            name={name}
            role={role}
            company={company}
            foundInfo={foundInfo}
          />

          <div style={{ width: "100%" }}>
            <ComposeCard
              emailSubject={emailSubject}
              updateSendStatus={updateSendStatus}
              emailBody={emailBody}
              name={name}
              messageUuid={messageUuid}
              saveAndSendButtonClicked={saveAndSendButtonClicked}
              handleSaveAndSendButtonClicked={handleSaveAndSendButtonClicked}
              isPrimaryMessage={true}
              clientUuid={decodedToken.ClientUUID}
              apiInstance={apiInstance}
              sendMessageFunction={sendOutboundEmail}
            />

            {followUpEmails.length > 0 && (
              <>
                {followUpEmails.map((email, index) => {
                  return (
                    <ComposeCard
                      key={email.uuid}
                      emailSubject={emailSubject}
                      updateSendStatus={updateSendStatus}
                      emailBody={email.body}
                      isPrimaryMessage={false}
                      clientUuid={decodedToken.ClientUUID}
                      dayOfSend={email.businessDaysAfterInitialEmail}
                      outboundEmailUuid={email.outboundEmailUuid}
                      messageUuid={email.uuid}
                      saveAndSendButtonClicked={saveAndSendButtonClicked}
                      followUpEmailNumber={index + 1}
                      apiInstance={apiInstance}
                      sendMessageFunction={sendFollowUpEmail}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProspectCard;
