import { Box, Container, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { API_URL } from "../../config/config";
import { DefaultApi, Configuration } from "../../api-client";
import googleButtonImg from "../../assets/web_light_rd_SI@1x.png"; // Import the PNG image

const Login = () => {
  const [redirectUri, setRedirectUri] = useState("");

  const apiConfig = new Configuration({
    basePath: API_URL,
  });

  const apiInstance = new DefaultApi(apiConfig);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiInstance.getOauth2RedirectUriRaw();
        const data = await response.value();
        setRedirectUri(data.redirectUri);
      } catch (error) {
        console.error("Error fetching redirect URI:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#2363eb",
      }}
    >
      <Box width="27rem" textAlign="center">
        <Typography
          variant="h6"
          component="p"
          sx={{
            my: "1.5rem",
            textAlign: "center",
            color: "white",
          }}
        ></Typography>
        <a href={redirectUri} style={{ textDecoration: "none" }}>
          <img
            src={googleButtonImg}
            alt="Login with Google"
            style={{ width: "200px", height: "auto" }} // Set the width to 200px and height auto
          />
        </a>
      </Box>
    </Container>
  );
};

export default Login;
